import Vue from 'vue'
import { decodeJWT, vuexMixin } from '@/utils/helpers'
import { systemDefinedActions } from '@/utils/plugins/permissions'

const { VUE_APP_DEFAULT_ROUTE_NAME_REDIRECT } = process.env

export const initialState = {
  jwt: null,
  invitation: {
    email: null,
    token: null,
  },
  permissions: null,
  owns: null,
  evaluatedActions: {},
  awsMarketplaceToken: null,
  awsMarketplaceSubscription: null,
  afterLogIn: {
    redirectRoute: initializeAfterLogInRedirectRoute(),
  },
  errors: {
    sso: [],
    signUp: [],
    login: [],
    verifyEmail: [],
    resetPassword: [],
  },
  sso: {},
  socialProvider: '',
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  defaultRoute: (state, _getters, _rootState, { orgCanonical }) => _.merge({
    ...state.afterLogIn.redirectRoute,
    params: {
      ...state.afterLogIn.redirectRoute?.params,
      orgCanonical,
    },
  }),
  isAuthenticated: (state) => !!state.jwt && !isTokenExpired(state.jwt),
  isOrgAdmin: (state) => _.keys(state?.permissions ?? {}).includes('organization:**'),
  isUsingMSP: (state, { userRole }) => _.isEmpty(userRole),
  jwtDecoded: (state) => !_.isNull(state.jwt)
    ? decodeJWT(state.jwt.split('.')[1]).cycloid
    : null,
  ssoUserProfile: ({ sso }) => sso?.user
    ? _.pick(sso.user, ['email', 'given_name', 'family_name', 'username', 'social_id'])
    : {},
  username: (state, { jwtDecoded }) => jwtDecoded?.user.username ?? null,
  userRole: (state, { jwtDecoded }) => jwtDecoded?.role,
}

const {
  mutations: { CLEAR_ERRORS, RESET_STATE, SET_ERRORS },
} = vuexMixin(initialState)

export const actions = {
  async REFRESH_TOKEN ({ commit, getters, rootState: { customers } }, { query } = {}) {
    if (!getters.isAuthenticated) return

    const orgCanonical = _.$get(customers, 'scopes[0].canonical', null)
    const childCanonical = _.$get(customers.scopes[customers.currentScopeDepth], 'canonical')

    const refreshTokenQuery = _.$isEmpty(query)
      ? {
          ...(orgCanonical ? { organization_canonical: orgCanonical } : {}),
          ...(childCanonical && childCanonical !== orgCanonical ? { child_canonical: childCanonical } : {}),
        }
      : query

    if (_.$isEmpty(refreshTokenQuery)) return

    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.refreshToken(refreshTokenQuery) || {}

    if (data) commit('SET_CREDENTIALS', data)
    if (errors) {
      if (errors[0].code === 'RequireOrganizationMFA') return 'MFAError'
      Vue.prototype.$cyRouter.push({ name: 'logout' }).catch(() => { /* silenced */ })
    }
  },

  async EVALUATE_USER_ACTIONS ({ commit, getters }, { actions: Actions = null, canonicals }) {
    const { isAuthenticated, isOrgAdmin, jwtDecoded } = getters
    const orgCanonical = _.$get(jwtDecoded, 'organization.canonical')

    if (_.some([!actions, !isAuthenticated, !orgCanonical, isOrgAdmin])) return

    await Promise.allSettled(Actions.map(async (action) => {
      const actionWithPolicies = _.$get(systemDefinedActions, action, {})
      const canonical = getActionCanonical(action, canonicals)

      const { data: evaluatedAction, errors } = await Vue.prototype.$cycloid.ydAPI.canDo(orgCanonical, actionWithPolicies, canonical) || {}

      const evaluated = _.$isEmpty(errors)
        ? { ...evaluatedAction, action }
        : { ok: false, action, entity_canonicals: [] }
      commit('SET_EVALUATED_ACTIONS', evaluated)
    }))
  },

  RESTORE_CREDENTIALS ({ commit, state }) {
    const jwt = state.jwt || localStorage[LSK.JWT]
    if (jwt && !isTokenExpired(jwt)) commit('SET_CREDENTIALS', { token: jwt })
  },

  async RETRIEVE_INVITATION_DATA ({ commit }, token) {
    const { data } = await Vue.prototype.$cycloid.ydAPI.getPendingInvitation(token) || {}
    if (data) commit('SET_INVITATION_DATA', { token, email: data.email })
  },

  async VERIFY_EMAIL ({ commit }, token) {
    const { errors } = await Vue.prototype.$cycloid.ydAPI.verifyEmail(token) || {}
    if (errors) commit('SET_ERRORS', { key: 'verifyEmail', errors })
  },
  async LOGIN ({ commit, dispatch, state, rootGetters }, { email, password, token, orgCanonical, next } = {}) {
    let dataLogin = null
    const processLogin = async () => {
      if (email && password) {
        commit('CLEAR_AUTH_ERRORS', 'login')
        const { data, errors } = await Vue.prototype.$cycloid.ydAPI.login({ email, password, orgCanonical }) || {}
        if (errors) {
          commit('SET_ERRORS', { key: 'login', errors })
          throw new Error('Login failed')
        }
        dataLogin = data
      } else if (token) {
        const { data, errors } = await Vue.prototype.$cycloid.ydAPI.emailAuthenticationVerification(token, orgCanonical) || {}
        if (errors) {
          next({ name: 'MFAInvalidLoginLink' }).catch(() => { /* silenced */ })
          throw new Error('Email authentication verification failed')
        }
        dataLogin = data
      }

      if (dataLogin) {
        commit('SET_CREDENTIALS', dataLogin)
        const { orgCanonical, orgName } = rootGetters
        const scope = { name: orgName, canonical: orgCanonical, jwt: state.jwt }
        commit('customers/SET_CURRENT_SCOPE', scope, { root: true })
        localStorage.setItem(LSK.APP_VERSION, process.env.VUE_APP_VERSION)
      }

      const jwt = (token || email) ? state.jwt : state.sso.token
      await Promise.all([
        dispatch('UPDATE_USER_SESSION', jwt, { root: true }),
        dispatch('FETCH_ORGS', undefined, { root: true }),
      ])
    }

    return processLogin()
  },

  LOGOUT ({ commit, rootGetters }) {
    const { orgCanonical } = rootGetters
    commit('user/CLEAR_PROFILE', null, { root: true })
    commit('organization/licence/SET_FETCHED', false, { root: true })
    commit('SET_DEFAULT_ROUTE', { name: 'dashboard', params: { orgCanonical } })
    commit('organization/RESET_ORG_STATE', null, { root: true })
    commit('customers/RESET_CUSTOMERS_STATE', null, { root: true })
    commit('CLEAR_CREDENTIALS')
    commit('RESET_AUTH_STATE')
    commit('RESET_STATE', null, { root: true })
    localStorage.removeItem(LSK.ORGANIZATION)
    sessionStorage.removeItem(LSK.ORGANIZATION)
  },

  async SIGNUP ({ state, commit }, user) {
    commit('CLEAR_AUTH_ERRORS', 'signUp')

    const { awsMarketplaceToken } = state

    const body = awsMarketplaceToken ? { aws_marketplace_token: awsMarketplaceToken, ...user } : user
    const apiMethodName = awsMarketplaceToken ? 'signUpAWSMarketplace' : 'signUp'

    const { errors } = await Vue.prototype.$cycloid.ydAPI[apiMethodName](body) || {}

    if (errors) commit('SET_ERRORS', { key: 'signUp', errors })
  },

  async SSO_SIGNUP ({ commit }, { socialProvider, user }) {
    commit('CLEAR_AUTH_ERRORS', 'sso')
    const { errors, data } = await Vue.prototype.$cycloid.ydAPI.createOAuthUser(socialProvider, user) || {}

    if (errors) commit('SET_ERRORS', { key: 'sso', errors })
    if (data) commit('SET_SSO', data)
  },

  async GET_SSO_USER ({ commit }, { socialProvider, ssoCode }) {
    commit('CLEAR_AUTH_ERRORS', 'sso')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getOAuthUser(socialProvider, ssoCode) || {}
    if (errors) commit('SET_ERRORS', { key: 'sso', errors })
    if (data) commit('SET_SSO', data)
  },

  async HANDLE_SAML2_AUTH ({ commit, dispatch, state }, { ssoCode, socialProvider = 'saml2' }) {
    commit('CLEAR_AUTH_ERRORS', 'sso')

    await dispatch('GET_SSO_USER', { socialProvider, ssoCode: { oauth_code: ssoCode } })

    if (_.$isEmpty(state.jwt)) await dispatch('SSO_SIGNUP', { socialProvider, user: state.sso.user })
    if (_.$isEmpty(state.errors.sso)) await dispatch('LOGIN')
  },

  async RESET_PASSWORD ({ commit }, { token, password }) {
    commit('CLEAR_AUTH_ERRORS', 'resetPassword')

    const { errors } = await Vue.prototype.$cycloid.ydAPI.passwordResetUpdate(token, password) || {}

    if (!_.$isEmpty(errors)) commit('SET_ERRORS', { key: 'resetPassword', errors })
  },
}

export const mutations = {
  CLEAR_AUTH_ERRORS: CLEAR_ERRORS,
  RESET_AUTH_STATE: RESET_STATE,
  SET_ERRORS,

  SET_CREDENTIALS (state, { token: jwt, permissions, owns }) {
    Vue.set(state, 'jwt', jwt)
    localStorage.setItem(LSK.JWT, jwt)

    Vue.set(state, 'permissions', permissions)
    Vue.set(state, 'owns', owns)

    permissions
      ? localStorage.setItem(LSK.PERMISSIONS, JSON.stringify(permissions))
      : localStorage.removeItem(LSK.PERMISSIONS)

    owns
      ? localStorage.setItem(LSK.OWNS, JSON.stringify(owns))
      : localStorage.removeItem(LSK.OWNS)
  },

  SET_INVITATION_DATA (state, { token, email }) {
    Vue.set(state, 'invitation', { token, email })
  },

  CLEAR_CREDENTIALS (state) {
    Vue.set(state, 'jwt', null)
    localStorage.removeItem(LSK.JWT)
    Vue.set(state, 'permissions', null)
    localStorage.removeItem(LSK.PERMISSIONS)
    Vue.set(state, 'owns', null)
    localStorage.removeItem(LSK.OWNS)
  },

  SET_DEFAULT_ROUTE (state, redirectRoute) {
    Vue.set(state.afterLogIn, 'redirectRoute', redirectRoute)
    localStorage.setItem(LSK.REDIRECT_ROUTE, JSON.stringify(redirectRoute))
  },

  SET_SSO (state, res) {
    Vue.set(state, 'sso', res)
    Vue.set(state, 'jwt', res.token)
  },

  SET_SOCIAL_PROVIDER (state, socialProvider) {
    Vue.set(state, 'socialProvider', socialProvider)
  },

  SET_EVALUATED_ACTIONS (state, { ok, entity_canonicals: allowedEntities, action }) {
    const evaluatedAction = _.$get(state.evaluatedAction, action, { entityCanonicals: [] })
    const entityCanonicals = _.$isEmpty(allowedEntities) ? [] : [...evaluatedAction.entityCanonicals, ...allowedEntities]
    Vue.set(state.evaluatedActions, action, { ok, entityCanonicals })
  },

  SET_AWS_MARKETPLACE_TOKEN (state, token) {
    Vue.set(state, 'awsMarketplaceToken', token)
  },

  SET_AWS_MARKETPLACE_SUBSCRIPTION (state, subscription) {
    Vue.set(state, 'awsMarketplaceSubscription', subscription)
  },
}

export function isTokenExpired (jwt) {
  let payload

  if (isTokenExpired.cachedToken === jwt) {
    payload = isTokenExpired.cachedPayload
  } else {
    const encPayload = jwt.split('.')[1]
    if (!encPayload) {
      throw new Error(`Invalid JWT token: ${jwt}`)
    }

    try {
      payload = decodeJWT(encPayload)
    } catch (error) {
      throw new Error(`Invalid payload in JWT token: ${jwt}. Original Error: ${error.message}`)
    }

    isTokenExpired.cachedToken = jwt
    isTokenExpired.cachedPayload = payload
  }

  return (payload.exp * 1000 - Date.now()) <= 0
}

export function initializeAfterLogInRedirectRoute () {
  const route = localStorage.getItem(LSK.REDIRECT_ROUTE)
  return !route
    ? { name: VUE_APP_DEFAULT_ROUTE_NAME_REDIRECT }
    : JSON.parse(route)
}

function getActionCanonical (action, canonicals = {}) {
  return {
    UpdatePipeline: canonicals.pipelineCanonical,
    UpdateProject: canonicals.projectCanonical,
  }?.[action]
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
