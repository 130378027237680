import VMdEditor from '@kangc/v-md-editor'
import '@kangc/v-md-editor/lib/style/base-editor.css'
import '@kangc/v-md-editor/lib/theme/style/github.css'
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js'
import Hljs from 'highlight.js'

VMdEditor.use(githubTheme, {
  Hljs,
})

export default VMdEditor
