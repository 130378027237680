/* eslint-disable vue/no-unused-properties */

import { sanitizeUrl } from '@braintree/sanitize-url'

export default {
  install (Vue) {
    Vue.mixin({
      methods: {
        $sanitizeUrl (url) {
          return sanitizeUrl(url)
        },
      },
    })
  },
}
