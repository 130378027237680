import Vue from 'vue'
import i18n from '@/utils/plugins/i18n'
import { vuexMixin } from '@/utils/helpers'

export const initialState = {
  detail: {},
  errors: [],
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  member: (state) => state.detail,
}

const {
  mutations: { CLEAR_ERRORS, RESET_STATE, SET_ERRORS },
} = vuexMixin(initialState)

export const actions = {
  async GET_ORG_MEMBER ({ commit, rootGetters: { orgCanonical } }, id) {
    commit('CLEAR_ERRORS')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getOrgMember(orgCanonical, id) || {}
    if (data) commit('SET_MEMBER', data)
    if (errors) commit('SET_ERRORS', { errors })
  },

  async UPDATE_ORG_MEMBER ({ commit, dispatch, rootGetters: { orgCanonical } }, { member, $router }) {
    commit('CLEAR_ERRORS')
    const { id, username } = member
    const { errors } = await Vue.prototype.$cycloid.ydAPI.updateOrgMember(orgCanonical, id, { role_canonical: member.role_canonical }) || {}
    if (errors) commit('SET_ERRORS', { errors })
    else {
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.organization.member.updated', { username }) }, { root: true })
      $router.push({ name: 'members' })
    }
  },

  async REMOVE_ORG_MEMBER ({ commit, dispatch, rootGetters: { orgCanonical, orgName } }, { id, $router }) {
    commit('CLEAR_ERRORS')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.removeOrgMember(orgCanonical, id) || {}
    if (errors) return commit('SET_ERRORS', { errors })
    else dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.tc('alerts.success.organization.members.unassigned', 1, { orgName, userNames: [id] }) }, { root: true })
    if ($router) $router.push({ name: 'members' })
  },
}

export const mutations = {
  CLEAR_ERRORS,
  RESET_MEMBER_STATE: RESET_STATE,
  SET_ERRORS,

  SET_MEMBER (state, member) {
    Vue.set(state, 'detail', member)
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
