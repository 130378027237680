import { vuexMixin } from '@/utils/helpers'
import Vue from 'vue'
import i18n from '@/utils/plugins/i18n'

export const initialState = {
  detail: null,
  errors: [],
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {}

const {
  mutations: { RESET_STATE, CLEAR_ERRORS, SET_ERRORS },
} = vuexMixin(initialState)

export const actions = {
  async CREATE_APPEARANCE ({ commit, dispatch, rootGetters: { orgCanonical } }, { appearance, $router }) {
    commit('CLEAR_APPEARANCE_ERRORS')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.createAppearance(orgCanonical, appearance) || {}
    if (data) {
      if ($router) $router.push({ name: 'orgSettings' })
      else commit('SET_APPEARANCE', data)
      const appearanceCanonical = data.canonical
      const content = i18n.t('alerts.success.appearance.created', { appearanceCanonical })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
    }
    if (errors) commit('SET_ERRORS', { errors })
  },

  async DELETE_APPEARANCE ({ commit, dispatch, rootGetters: { orgCanonical } }, { appearance, $router }) {
    const { canonical: appearanceCanonical } = appearance
    commit('CLEAR_APPEARANCE_ERRORS')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteAppearance(orgCanonical, appearanceCanonical) || {}
    if (errors) commit('SET_ERRORS', { errors })
    else {
      commit('RESET_APPEARANCE_STATE')
      if (appearance.isActive) dispatch('FETCH_APP_APPEARANCE', null, { root: true })
      const content = i18n.t('alerts.success.appearance.deleted', { appearanceCanonical })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
      if ($router) $router.replace({ name: 'orgSettings' })
    }
  },

  async GET_APPEARANCE ({ commit, rootGetters: { orgCanonical } }, appearanceCanonical) {
    commit('CLEAR_APPEARANCE_ERRORS')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getAppearance(orgCanonical, appearanceCanonical) || {}
    if (data) commit('SET_APPEARANCE', data)
    if (errors) commit('SET_ERRORS', { errors })
  },

  async UPDATE_APPEARANCE ({ commit, dispatch, rootGetters: { orgCanonical } }, { appearance, successMessage, $router }) {
    commit('CLEAR_APPEARANCE_ERRORS')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.updateAppearance(orgCanonical, appearance) || {}
    if (data) {
      if ($router) $router.push({ name: 'orgSettings' })
      else commit('SET_APPEARANCE', data)
      const content = successMessage || i18n.t('alerts.success.appearance.updated', { appearanceCanonical: data.canonical })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
    }
    if (errors) commit('SET_ERRORS', { errors })
  },
}

export const mutations = {
  CLEAR_APPEARANCE_ERRORS: CLEAR_ERRORS,
  RESET_APPEARANCE_STATE: RESET_STATE,
  SET_ERRORS,

  SET_APPEARANCE (state, appearance) {
    Vue.set(state, 'detail', appearance)
  },
}

export {
  STATE as state,
  GETTERS as getters,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
