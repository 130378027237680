import Vue from 'vue'

/**
 * ! plugins must be imported before router and store
 * - 1⃣ plugins contains vendor's css and also exposing lodash globally
 * - 2⃣ we also have global styles
 * - 3⃣ the router imports component styles
 * - we want import order to be [vendor, global, component]
 *   so we can easily override vendor styles
 */
import vuetify from '@/utils/plugins/vuetify'
import '@/utils/plugins' /* 1⃣ */
import '@/vendor/web-assembly'
import '@/assets/styles/scss/index.scss' /* 2⃣ */
import PortalVue from 'portal-vue'
import store from '@/store'
import router from '@/router' /* 3⃣ */
import i18n from '@/utils/plugins/i18n'
import CyApp from '@/app'

const { NODE_ENV } = process.env
const isProduction = NODE_ENV === 'production'
const isDevelop = NODE_ENV === 'development'
const isStaging = NODE_ENV === 'staging'

// Expose Vuex store to Cypress
if (window.Cypress) window.__store__ = store

// Set specific Vue configurations
Vue.config.performance = !isProduction
Vue.config.productionTip = false
Vue.config.devtools = isDevelop || isStaging
Vue.use(PortalVue)

// eslint-disable-next-line no-new
new Vue({
  store,
  router,
  i18n,
  vuetify,
  el: '#app',
  render: (h) => h(CyApp),
})
