import { imageExists } from '@/utils/helpers'
import REGEX from '@/utils/config/regex'
import _defineCustomShapes from './custom-shapes'
import _defineCustomLinks from './custom-links'
import _generate from './generate'
import _populate from './populate'

export const defineCustomShapes = _defineCustomShapes
export const defineCustomLinks = _defineCustomLinks
export const generate = _generate
export const populate = _populate

export function centerElement (cell) {
  const { x, y } = getCellModel(cell).attributes.position
  this.paperScroller.center(x, y)
  this.highlightCell(cell)
}

export function clearCurrentCell () {
  if (this.halo) this.halo.remove()
  if (this.currentCell) this.unhighlightAll()
  this.currentCell = null
}

export function getCanonicalFromCell (cell) {
  const hasCanonical = _.has(cell, 'canonical')
  const hasAttributes = _.has(cell, 'attributes')
  const hasAttrs = _.has(cell, 'attrs')
  const hasModel = _.has(cell, 'model')
  if (hasCanonical) return cell.canonical
  if (hasAttributes) return Object.values(cell.attributes.attrs.name).join('')
  if (hasAttrs) return Object.values(cell.attrs.name).join('')
  if (hasModel) return Object.values(cell.model.attr('name')).join('')
  console.error(`[getCanonicalFromCell] helper cannot return anything`, { hasCanonical, hasAttributes, hasAttrs, hasModel }, cell)
}

export function getElementRefs (fullCanonical) {
  const modules = fullCanonical.match(REGEX.TF.MODULE_PATH)
  const reducedCanonical = fullCanonical.replace(REGEX.TF.MODULE_PATH, '')
  const [type, name = null] = reducedCanonical.split('.')

  return {
    canonical: fullCanonical,
    modules: modules ? modules.map((module) => module.replace(/\.$/, '')) : null,
    name,
    provider: type.split('_')[0],
    type,
    typeKey: _.without(fullCanonical.split('.'), name).join('.'),
  }
}

export function getCellModel (cell) {
  return _.get(cell, 'model', cell)
}

export function getCellType (theCell, { lowerFirstLetter = false, isConfigProp = false } = {}) {
  const cell = _.get(theCell, 'model', theCell)
  const type = cell.attributes.type.split('.')[1]
  if (lowerFirstLetter) return _.lowerFirst(type)
  if (isConfigProp) return type.toLowerCase() === 'datasource' ? 'data' : 'resource'
  return type
}

export function getType (entityType) {
  return String(entityType).toLowerCase() === 'datasource'
    ? 'data'
    : 'resource'
}

export function highlightCell (cell) {
  this.unhighlightAll()
  cell.highlight(null, highlighterOptions(cell))
  this.halo = this.generate.halo(cell)
}
export function highlighterOptions (cell) {
  if (!cell) return
  return {
    highlighter: {
      name: 'stroke',
      options: {
        padding: 6,
        rx: 5,
        ry: 5,
        attrs: {
          'stroke-dasharray': '1,4',
          'stroke-linecap': 'round',
          'stroke-width': 2,
          stroke: 'rgb(36,52,73)',
        },
      },
    },
  }
}

/** Populates the element with the correct image path.
 * If the image is not accessible using the path provided, then it shall show the default image.
 * If the elements are for `vault`, then it populates each image field with the path to the vault logo.
 *
 * @param {Object} element    - the element, using attrs { canonical, image }
 * @param {String} entityType - either 'resource' or 'dataSource'
 *
 * @returns `Array`
 */
export function populateElementImage (element, entityType) {
  const { canonical, image } = element

  if (canonical.startsWith('vault_')) return '/static/images/stackcraft/elements/default/vault.svg'
  if (image?.endsWith('.svg') && imageExists(image)) return image

  return `/static/images/stackcraft/elements/default/${entityType}.svg`
}

export function setCurrentCell (cell) {
  if (this.getCellType(cell) === 'Link') return
  this.currentCell = cell
  this.highlightCell(this.currentCell)
}

export function unhighlightAll () {
  this.paper.findViewsInArea(this.paper.getArea()).forEach((cell) => {
    cell.unhighlight(null, this.highlighterOptions(cell))
  })
  this.paper.removeTools()
  if (this.halo) this.halo.remove()
}

export default {
  defineCustomLinks,
  defineCustomShapes,
  generate,
  populate,
  // methods
  centerElement,
  clearCurrentCell,
  getElementRefs,
  getCanonicalFromCell,
  getCellModel,
  getCellType,
  getType,
  highlightCell,
  highlighterOptions,
  populateElementImage,
  setCurrentCell,
  unhighlightAll,
}
