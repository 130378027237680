import Vue from 'vue'
import { vuexMixin } from '@/utils/helpers'
import { InfraViewDiagram, InfraViewElement } from '@/utils/classes'
import REGEX from '@/utils/config/regex'
import { populateElementImage, getElementRefs } from '@/utils/helpers/rappid'

export const initialState = {
  diagram: null,
  errors: [],
  fetchInProgress: {
    diagram: false,
  },
  mode: 'diagram',
  runningInstance: null,
  runningInstances: null,
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  allResources: (state, getters) => !getters.hasResources ? [] : _.flatten(_.map(_.values(state.diagram.config.resource), _.values)),
  allResourcesGroupedByType: (_state, getters) => !getters.hasResources
    ? {}
    : getters.allResources.reduce((resources, resource) => {
      resources[resource._type] = [...resources[resource._type] || [], resource]
      return resources
    }, {}),
  allResourceTypes: (_state, getters) => !getters.hasResources ? [] : _.uniq(_.map(getters.allResources, '_type')),
  hasDiagram: (state) => _.has(state.diagram, 'cells'),
  hasInvalidExternalBackend: (state, { hasNoExternalBackend }) => !hasNoExternalBackend && !_.isEmpty(state.errors),
  hasNoExternalBackend: (state) => _.some(state.errors, ({ code }) => code === 'NotFound'),
  hasResources: (state) => !_.isEmpty(state.diagram?.config.resource),
}

const {
  mutations: { CLEAR_ERRORS, RESET_STATE, SET_ERRORS, START_FETCH, STOP_FETCH },
} = vuexMixin(initialState)

export const actions = {
  async GET_INFRA ({ commit, rootGetters: { orgCanonical, projectCanonical } }, envCanonical) {
    commit('SET_INFRA_DIAGRAM', null)
    commit('CLEAR_INFRA_ERRORS')
    commit('SET_MODE', { mode: 'diagram' })
    commit('START_FETCH', 'diagram')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getProjInfrastructure(orgCanonical, projectCanonical, envCanonical) || {}
    if (data) {
      data.config = await populateElementSchemas(orgCanonical, data)
      commit('SET_INFRA_DIAGRAM', data)
    }
    if (errors) commit('SET_ERRORS', { errors })
    commit('STOP_FETCH', 'diagram')
  },

  async GET_ELEMENT_BY_TYPE ({ rootGetters: { orgCanonical } }, { type, isResource = true }) {
    const providerId = type.split('_')[0]
    const { data } = isResource
      ? await Vue.prototype.$cycloid.ydAPI.getTerraformProviderResource({ orgCanonical, providerId, resourceId: type }) || {}
      : await Vue.prototype.$cycloid.ydAPI.getTerraformProviderDataSource({ orgCanonical, providerId, dataSourceId: type }) || {}
    return data
  },

  async GET_RUNNING_INFRA ({ commit, dispatch, rootGetters: { orgCanonical, projectCanonical } }, { credentialCanonical, infraTypes, defaultTags }) {
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getOrgRunningInfraAWS(orgCanonical, credentialCanonical, projectCanonical, infraTypes, defaultTags) || {}
    if (data) commit('SET_RUNNING_INFRA', data)
    if (errors) {
      commit('SET_ERRORS', { errors })
      dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
    }
  },

  async GET_RUNNING_INSTANCES ({ commit, dispatch, rootGetters: { orgCanonical, projectCanonical } }, { credentialCanonical, defaultTags }) {
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getOrgRunningInfraAWSInstances(orgCanonical, credentialCanonical, projectCanonical, defaultTags) || {}
    if (data) commit('SET_RUNNING_INSTANCES', data)
    if (errors) {
      commit('SET_ERRORS', { errors })
      dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
    }
  },
}

export const mutations = {
  CLEAR_INFRA_ERRORS: CLEAR_ERRORS,
  RESET_INFRA_STATE: RESET_STATE,
  SET_ERRORS,
  START_FETCH,
  STOP_FETCH,

  SET_INFRA_DIAGRAM (state, diagram) {
    diagram
      ? Vue.set(state, 'diagram', new InfraViewDiagram(diagram))
      : Vue.set(state, 'diagram', null)
  },

  SET_RUNNING_INFRA (state, runningInfra) {
    Vue.set(state, 'runningInfra', runningInfra)
  },

  SET_RUNNING_INSTANCES (state, runningInstances) {
    Vue.set(state, 'runningInstances', runningInstances)
  },

  SET_MODE (state, { mode }) {
    if (!['diagram', 'list', 'oldVersion'].includes(mode)) throw new Error(`[vuex]: SET_MODE was passed an invalid mode: ${mode}`)
    Vue.set(state, 'mode', mode)
  },
}

/**
 * Parses an element, returned from api, into a nested Object, for use on FE.
 *
 * - **i.e.** _all maps, lists, sets etc are represented as a single key/value pair_
 * - **e.g.** _`ingress.2452114087.security_groups.892603287`_
 *   - Thus the additional need to parse the keyPaths themselves via `getCorrectedKey`, else it creates MASSIVE arrays.
 *
 * @param {Object} element            - the element (resource/dataSource) returned from api
 */
function parseElement (element) {
  // TODO: FE#6432 make this function more testable/efficient

  const parsedElement = {}
  const isItemToStrip = (key, value) => REGEX.TF.COUNT_KEYS.test(key) || _.$isEmpty(value)
  const textToReplace = (key, index) => key.replace(new RegExp(key.split('.')[1], 'g'), index)

  const keyPaths = _(element)
    .keys()
    .filter((key) => key.includes('.') || !REGEX.TF.COUNT_KEYS.test(key))
    .map((key) => _.chunk(key.split('.'), 2).filter((keys) => keys.length === 2).map((chunk) => chunk.join('.')))
    .flattenDeep()
    .uniq()
    .filter((key) => /\d+/.test(key))
    .sort()
    .groupBy((key) => key.split('.')[0])
    .map((keys) => keys.map((key, index) => [key, textToReplace(key, index)]))
    .flatten()
    .fromPairs()
    .value()

  for (const [key, value] of _.entries(element)) {
    const tagKey = key.startsWith('tags') ? key.substr(5) : ''
    const isTag = !_.isEmpty(tagKey)
    const keysToReplace = _.filter(_.keys(keyPaths), (keyPath) => key.includes(keyPath))
    const replaceKey = !_.isEmpty(keysToReplace)

    if (isItemToStrip(key, value)) continue

    if (replaceKey) {
      const newKey = getCorrectedKey(key, keyPaths)
      _.set(parsedElement, newKey, value)
      continue
    }

    if (isTag) _.set(parsedElement, `tags['${tagKey}']`, value)
    else _.set(parsedElement, key, value)
  }

  return parsedElement
}

async function populateElementSchemas (orgCanonical, { config }) {
  function setConfigData (element, baseProps) {
    const { entityType, typeKey, name } = baseProps
    const parsedElement = parseElement(element)
    const resourceOrData = entityType === 'resource' ? 'resource' : 'data'
    config[resourceOrData][typeKey][name] = new InfraViewElement(parsedElement, {
      ...baseProps,
      image: populateElementImage(baseProps, entityType),
      rappidId: 123, // ! add the real rappidId!!
    })
  }

  const resourcePromises = _.entries(config.resource).map(async ([typeKey, resourcesObj]) => {
    const { type } = getElementRefs(typeKey)
    const entityType = 'resource'
    const baseProps = await actions.GET_ELEMENT_BY_TYPE({ rootGetters: { orgCanonical } }, { type, isResource: true })
    for (const [name, element] of _.entries(resourcesObj)) {
      const canonical = `${typeKey}.${name}`
      setConfigData(element, { ...baseProps, entityType, canonical, type, typeKey, name })
    }
  })
  const datasourcePromises = _.entries(config.data).map(async ([typeKey, dataSourcesObj]) => {
    const { type } = getElementRefs(typeKey)
    const entityType = 'dataSource'
    const baseProps = await actions.GET_ELEMENT_BY_TYPE({ rootGetters: { orgCanonical } }, { type, isResource: false })
    for (const [name, element] of _.entries(dataSourcesObj)) {
      const canonical = `${typeKey}.${name}`
      setConfigData(element, { ...baseProps, entityType, canonical, type, typeKey, name })
    }
  })
  await Promise.allSettled([...resourcePromises, ...datasourcePromises])

  return config
}

/**
 * Replaces all segments of a keyPath where there are replacements defined on the objReplaceDictionary
 *
 * @param {String} keyPath              - The current key/path, to be renamed.
 * @param {Object} keyPathsMap          - The key/path object that contains all of the replacements.
 *
 * @example getCorrectedKey('ingress.2452114087.security_groups.892603287', {
    'ingress.2452114087': 'ingress.0',
    'ingress.305821275': 'ingress.1',
    'security_groups.2921313341': 'security_groups.0',
    'security_groups.892603287': 'security_groups.1'
 * })
 * // would return
 * 'ingress.0.security_groups.1'
 */
function getCorrectedKey (keyPath, keyPathsMap) {
  if (_.isEmpty(keyPathsMap)) return keyPath
  let pattern = []
  for (const name in keyPathsMap) pattern.push(name.replace(/([[^$.|?*+(){}\\])/g, '\\$1'))
  pattern = new RegExp(pattern.join('|'), 'g')
  return keyPath.replace(pattern, (match) => keyPathsMap[match])
}

export const privateMethods = {
  parseElement,
  populateElementSchemas,
  getCorrectedKey,
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
