import { cgIcons } from '@/assets/images'

const paperSize = 3000
const gridSize = 10

export const meta = {
  paperSize,
  gridSize,
}

/* eslint-disable consistent-this */
export default function generate (self) {
  const { $joint: joint } = self

  return {
    CyInfraViewDiagram: {
      graph () {
        const graph = new joint.dia.Graph()
        graph.fromJSON(self.diagram || { cells: [] })
        return graph
      },
      paper () {
        const graph = self.graph
        const paper = new joint.dia.Paper({
          model: graph,
          gridSize,
          width: paperSize * 2,
          height: paperSize,
          drawGrid: true,
          linkPinning: false,
          interactive: false,
          linkView: joint.dia.LinkView.extend({
            options: joint.util.defaults({
              doubleLinkTools: true,
              longLinkLength: 100,
              shortLinkLength: 36,
            }, joint.dia.LinkView.prototype.options),
          }),
          defaultLink: new joint.shapes.custom.Link(),
          defaultConnector: {
            name: 'rounded',
            args: {
              radius: 20,
            },
          },
          defaultConnectionPoint: {
            name: 'boundary',
            args: {
              stroke: true,
            },
          },
          background: {
            color: '#e2e6e6',
          },
        })
        self.paperScroller = new joint.ui.PaperScroller({
          paper,
          cursor: 'grab',
          padding: 0,
          contentOptions (paperScroller) {
            const { height, width } = paperScroller.getVisibleArea()
            return {
              padding: {
                bottom: height / 2,
                top: height / 2,
                left: width / 2,
                right: width / 2,
              },
              maxWidth: paperSize,
              maxHeight: paperSize,
              allowNewOrigin: 'any',
            }
          },
        })

        const paperScroller = { original: self.$el.querySelector('.joint-paper-scroller'), new: self.paperScroller.render().el }
        const paperElement = self.$el.querySelector('.cy-paper')
        if (!paperScroller.original) paperElement?.appendChild(paperScroller.new)
        else paperElement?.replaceChild(paperScroller.new, paperScroller.original)
        self.$el.querySelector('.joint-paper-scroller')?.classList.add('cy-scrollbars')

        paper.on({
          'blank:pointerdown' (evt) {
            self.dragCoords = { clientX: evt.clientX, clientY: evt.clientY }
            self.paperScroller.startPanning(evt)
            // we still need to attach an event handling callback to the pointerdown event, even though we set
            // $joint.ui.Popup() autoClose property to true (see handleResourceMouseEnter
            // if not setting $joint.ui.Popup() autoClose property to true we get a JavaScript console error (...Rappid bug?)
            // saying this because the autoClose property is meant exactly to avoid doing things manually
            self.handlePopupClose()
          },
          'blank:pointerup' (_cellView, a, b) {
            const { clientX: startX, clientY: startY } = self.dragCoords
            const { _clientX: endX, _clientY: endY } = self.paperScroller
            if (startX === endX && startY === endY) self.closeRightPanel()
          },
          'blank:pointerdblclick' () {
            self.closeRightPanel()
          },
          'element:pointerup' (cellView) {
            self.setCurrentCell(cellView)
            self.openRightPanel(cellView)
          },
          'element:mouseenter' (cellView) {
            self.handleResourceMouseEnter(cellView)
          },
        })
        return paper
      },
      customShape (shapeName, { position, attrs }) {
        return shapeName
          ? new joint.shapes.custom[shapeName]({ position, attrs })
          : console.error('Error in generate.customShape() - shapeName is not defined')
      },
      toolbar () {
        const toolbar = new joint.ui.Toolbar({
          references: {
            paperScroller: self.paperScroller,
            commandManager: self.commandManager,
          },
          tools: [
            {
              type: 'label',
              name: 'zoom-label',
              text: 'zoom',
            },
            {
              type: 'zoom-slider',
              name: 'zoom-slider',
              min: 20,
              max: 200,
              step: 10,
              value: 100,
              unit: '%',
            },
            // ! These are the visible icons
            // - they have no functionality, just there to look pretty
            //   if we were able to just assign .icon props to the pseudo-buttons
            //   below this selectButtonGroup, then we wouldn't need this anymore
            {
              type: 'selectButtonGroup',
              name: 'actions',
              multi: true,
              options: [
                {
                  value: 'fit',
                  content: `
                  <img
                    src="${cgIcons.zoomToFit}"
                    height="20"
                    width="20">
                  `,
                },
              ],
            },
            // ! This is a pseudo-button (hidden)
            // - the reason to do this is to use the inbuilt `zoomToFit` method instead of
            //   doing it manually
            {
              type: 'zoomToFit',
              name: 'fit',
              min: 0,
              max: 10,
              step: 1,
              attrs: {
                button: {
                  class: 'infraview-toolbar-action--fit',
                  'data-tooltip': self.$t('fit'),
                  'data-tooltip-position': 'top',
                  'data-tooltip-position-selector': '.joint-widget',
                  'data-tooltip-viewport-selector': '.cy-paper',
                },
              },
            },
          ],
        })
        const toolbarElement = { original: self.$el.querySelector('.joint-toolbar'), new: toolbar.render().el }
        const paperElement = self.$el.querySelector('.cy-paper')
        if (!toolbarElement.original) paperElement.appendChild(toolbarElement.new)
        else paperElement.replaceChild(toolbarElement.new, toolbarElement.original)
        self.$el.querySelector('.joint-toolbar').classList.add('cy-toolbar')
        return toolbar
      },
      commandManager () {
        return new joint.dia.CommandManager({ graph: self.graph })
      },
      tooltips () {
        return new joint.ui.Tooltip({
          rootTarget: '.rappid',
          target: '[data-tooltip]',
          direction: 'auto',
          padding: 10,
        })
      },
      halo (cellView) {
        return new joint.ui.Halo({
          cellView,
          boxContent: false,
          clearOnBlankPointerdown: false,
          handles: [],
        }).render()
      },
    },
  }[self.$options.name]
}
