import Vue from 'vue'
import i18n from '@/utils/plugins/i18n'
import { vuexMixin } from '@/utils/helpers'

export const initialState = {
  errors: {
    kpi: [],
    kpiFavorites: [],
    favorite: [],
  },
  KPIs: [],
  favoriteKPIs: [],
  KPI: {},
  filters: {
    datePeriod: {
      label: null,
      range: '1w',
      value: {
        begin: null,
        end: null,
      },
    },
  },
  filtersFavorites: {
    datePeriod: {
      label: null,
      range: '1w',
      value: {
        begin: null,
        end: null,
      },
    },
    project: null,
  },
  isFetchInProgress: {
    KPIs: false,
    favoriteKPIs: false,
  },
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {}

const {
  mutations: { SET_ERRORS, CLEAR_ERRORS, RESET_STATE },
} = vuexMixin(initialState)

export const actions = {
  async GET_KPIS ({ commit, rootGetters: { orgCanonical, projectCanonical }, state: { filters } }, envCanonical = null) {
    commit('CLEAR_KPI_ERRORS', 'errors.kpi')
    commit('START_FETCH', 'KPIs')
    const { begin, end } = filters.datePeriod.value
    const beginUnix = begin ? begin / 1000 : null
    const endUnix = end ? end / 1000 : null
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getKPIs(
      orgCanonical,
      filters.project?.canonical || projectCanonical,
      envCanonical,
      beginUnix,
      endUnix,
    ) || {}
    if (data) commit('SET_KPIS', data)
    if (errors) commit('SET_KPI_ERRORS', { key: 'errors.kpi', errors })
    commit('STOP_FETCH', 'KPIs')
  },

  async GET_FAVORITE_KPIS ({ commit, rootGetters: { orgCanonical, projectCanonical }, state: { filtersFavorites } }, envCanonical = null) {
    commit('CLEAR_KPI_ERRORS', 'errors.kpiFavorites')
    commit('START_FETCH', 'favoriteKPIs')
    const { begin, end } = filtersFavorites.datePeriod.value
    const beginUnix = begin ? begin / 1000 : null
    const endUnix = end ? end / 1000 : null
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getKPIs(
      orgCanonical,
      filtersFavorites.project?.canonical || projectCanonical,
      envCanonical,
      beginUnix,
      endUnix,
      true,
    ) || {}
    if (data) commit('SET_FAVORITE_KPIS', data)
    if (errors) commit('SET_KPI_ERRORS', { key: 'errors.kpiFavorites', errors })
    commit('STOP_FETCH', 'favoriteKPIs')
  },

  async GET_KPI ({ commit, rootGetters: { orgCanonical } }, KPICanonical) {
    commit('CLEAR_KPI_ERRORS', 'errors.kpi')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getKPI(orgCanonical, KPICanonical) || {}
    if (data) commit('SET_KPI', data)
    if (errors) commit('SET_KPI_ERRORS', { key: 'errors.kpi', errors })
  },

  async CREATE_KPI ({ commit, dispatch, rootGetters: { orgCanonical } }, KPI) {
    commit('CLEAR_KPI_ERRORS', 'errors.kpi')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.createKPI(orgCanonical, KPI) || {}
    if (errors) commit('SET_KPI_ERRORS', { key: 'errors.kpi', errors })
    else {
      await dispatch('GET_KPIS')
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.KPI.created') }, { root: true })
    }
  },

  async UPDATE_KPI ({ commit, dispatch, rootGetters: { orgCanonical } }, KPI) {
    commit('CLEAR_KPI_ERRORS', 'errors.kpi')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.updateKPI(orgCanonical, KPI) || {}
    if (errors) commit('SET_KPI_ERRORS', { key: 'errors.kpi', errors })
    else {
      await dispatch('GET_KPIS')
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.KPI.updated', { KPIName: KPI.name }) }, { root: true })
    }
  },

  async DELETE_KPI ({ commit, dispatch, rootGetters: { orgCanonical } }, KPI) {
    commit('CLEAR_KPI_ERRORS', 'errors.kpi')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteKPI(orgCanonical, KPI.canonical) || {}
    if (errors) commit('SET_KPI_ERRORS', { key: 'errors.kpi', errors })
    else {
      await dispatch('GET_KPIS')
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.KPI.deleted', { KPIName: KPI.name }) }, { root: true })
    }
  },

  async SET_FAVORITE_KPI ({ commit, dispatch, rootGetters: { orgCanonical } }, KPI) {
    commit('CLEAR_KPI_ERRORS', 'errors.favorite')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.createKPIFavorite(orgCanonical, KPI.canonical) || {}
    if (errors) commit('SET_KPI_ERRORS', { key: 'errors.favorite', errors })
    else dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.KPI.favorite.created') }, { root: true })
  },

  async UNSET_FAVORITE_KPI ({ commit, dispatch, rootGetters: { orgCanonical } }, KPI) {
    commit('CLEAR_KPI_ERRORS', 'errors.favorite')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteKPIFavorite(orgCanonical, KPI.canonical) || {}
    if (errors) commit('SET_KPI_ERRORS', { key: 'errors.favorite', errors })
    else dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.KPI.favorite.deleted') }, { root: true })
  },
}

export const mutations = {
  CLEAR_KPI_ERRORS: CLEAR_ERRORS,
  RESET_KPI_STATE: RESET_STATE,
  SET_KPI_ERRORS: SET_ERRORS,

  SET_KPI_FILTERS (state, { key, value }) {
    Vue.set(state.filters, key, value)
  },

  SET_KPI_FILTERS_FAVORITES (state, { key, value }) {
    Vue.set(state.filtersFavorites, key, value)
  },

  SET_KPIS (state, KPIs) {
    Vue.set(state, 'KPIs', KPIs)
  },

  SET_FAVORITE_KPIS (state, KPIs) {
    Vue.set(state, 'favoriteKPIs', KPIs)
  },

  SET_KPI (state, KPI) {
    Vue.set(state, 'KPI', KPI)
  },

  START_FETCH (state, key) {
    Vue.set(state.isFetchInProgress, key, true)
  },

  STOP_FETCH (state, key) {
    Vue.set(state.isFetchInProgress, key, false)
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
