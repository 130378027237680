import Vue from 'vue'
import i18n from '@/utils/plugins/i18n'
import { vuexMixin } from '@/utils/helpers'

export const initialState = {
  detail: null,
  diff: null,
  variables: null,
  syncDiff: null,
  errors: {
    diff: [],
    pipeline: [],
    syncDiff: [],
    variables: [],
  },
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  pipelineDiff: (state) => _.get(state, 'diff', null),
}

const {
  mutations: { SET_ERRORS, CLEAR_ERRORS, RESET_STATE },
} = vuexMixin(initialState)

export const actions = {
  async CREATE_PIPELINE ({ commit, rootGetters: { orgCanonical, projectCanonical } }, pipeline) {
    commit('CLEAR_PIPELINE_ERRORS', 'pipeline')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.createPipeline(orgCanonical, projectCanonical, pipeline) || {}

    if (errors) commit('SET_ERRORS', { key: 'pipeline', errors })
    return _.isEmpty(errors)
  },

  async DELETE_PIPELINE ({ commit, dispatch, rootGetters: { orgCanonical }, state }, { projectCanonical, pipelineCanonical }) {
    commit('CLEAR_PIPELINE_ERRORS', 'pipeline')
    const isStartStop = pipelineCanonical.startsWith('start-stop-')
    const envCanonical = _.replace(_.replace(pipelineCanonical, 'start-stop', ''), `-${projectCanonical}-`, '')

    const { errors } = await Vue.prototype.$cycloid.ydAPI.deletePipeline(orgCanonical, projectCanonical, pipelineCanonical) || {}
    if (errors) commit('SET_ERRORS', { key: 'pipeline', errors })
    else {
      if (state.detail?.canonical === pipelineCanonical) commit('RESET_PIPELINE_STATE')
      const message = isStartStop
        ? i18n.t('alerts.success.environment.removeScheduling', { envCanonical })
        : i18n.t('alerts.success.pipeline.deleted', { pipelineCanonical })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: message }, { root: true })
    }
  },

  async GET_PIPELINE ({ commit, rootGetters: { orgCanonical, projectCanonical } }, pipelineCanonical) {
    commit('CLEAR_PIPELINE_ERRORS', 'pipeline')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getPipeline(orgCanonical, projectCanonical, pipelineCanonical) || {}
    if (errors) commit('SET_ERRORS', { key: 'pipeline', errors })
    if (data) commit('SET_PIPELINE', data)
  },

  async GET_PIPELINE_DIFF ({ commit, dispatch, rootGetters: { orgCanonical } }, { pipelineCanonical, config }) {
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.diffPipeline(orgCanonical, pipelineCanonical, config) || {}
    if (data || _.isNull(data)) commit('SET_PIPELINE_DIFF', data)
    if (errors) {
      dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
      commit('SET_ERRORS', { key: 'diff', errors })
    }
    return _.isEmpty(errors)
  },

  async GET_PIPELINE_VARIABLES ({ commit, rootGetters: { orgCanonical, projectCanonical } }, { pipelineCanonical }) {
    commit('CLEAR_PIPELINE_ERRORS', 'variables')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getPipelineVariables(orgCanonical, projectCanonical, pipelineCanonical) || {}
    if (data) commit('SET_PIPELINE_VARIABLES', data)
    if (errors) commit('SET_ERRORS', { key: 'variables', errors })
  },

  async UNPAUSE_PIPELINE ({ commit, dispatch, rootGetters: { orgCanonical, projectCanonical } }, { pipelineCanonical, showNotification = false }) {
    commit('CLEAR_PIPELINE_ERRORS', 'pipeline')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.unpausePipeline(orgCanonical, projectCanonical, pipelineCanonical) || {}
    if (_.isEmpty(errors) && showNotification) dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.pipeline.toggle.unpaused') }, { root: true })
    if (!_.isEmpty(errors)) {
      commit('SET_ERRORS', { key: 'pipeline', errors })
      dispatch('alerts/SHOW_ALERT', { type: 'warning', content: i18n.t('alerts.warning.pipeline.togglePause') }, { root: true })
    }
    return _.isEmpty(errors)
  },

  async SYNCED_PIPELINE ({ commit, rootGetters: { orgCanonical } }, { pipelineCanonical }) {
    commit('CLEAR_PIPELINE_ERRORS', 'syncDiff')
    commit('SET_PIPELINE_SYNCED_DIFF', null)
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.syncedPipeline(orgCanonical, pipelineCanonical) || {}
    if (data) commit('SET_PIPELINE_SYNCED_DIFF', data)
    if (errors) commit('SET_ERRORS', { key: 'syncDiff', errors })
  },
}

export const mutations = {
  CLEAR_PIPELINE_ERRORS: CLEAR_ERRORS,
  RESET_PIPELINE_STATE: RESET_STATE,
  SET_ERRORS,

  SET_PIPELINE (state, pipeline) {
    Vue.set(state, 'detail', pipeline)
  },

  SET_PIPELINE_DIFF (state, diff) {
    Vue.set(state, 'diff', diff)
  },

  SET_PIPELINE_SYNCED_DIFF (state, diff) {
    Vue.set(state, 'syncDiff', diff)
  },

  SET_PIPELINE_VARIABLES (state, variables) {
    Vue.set(state, 'variables', variables)
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
