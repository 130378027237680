import { vuexMixin } from '@/utils/helpers'
import Vue from 'vue'
import i18n from '@/utils/plugins/i18n'
import ebMapping from '@/utils/config/external-backends-mapping'

export const initialState = {
  detail: {},
  fetchInProgress: false,
  errors: [],
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {}

const {
  mutations: { CLEAR_ERRORS, RESET_STATE, SET_ERRORS },
} = vuexMixin(initialState)

export const actions = {
  async CREATE_DEFAULT_EXTERNAL_BACKEND ({ commit, dispatch, rootGetters: { orgCanonical } }, externalBackend) {
    commit('CLEAR_DEFAULT_EXTERNAL_BACKEND_ERRORS')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.createExternalBackend(orgCanonical, externalBackend) || {}
    if (data) commit('SET_DEFAULT_EXTERNAL_BACKEND', data)
    if (errors) {
      dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
      commit('SET_ERRORS', { errors })
    }
  },

  async DELETE_DEFAULT_EXTERNAL_BACKEND ({ state, commit, dispatch, rootGetters: { orgCanonical } }) {
    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteExternalBackend(orgCanonical, state.detail.id) || {}
    if (errors) dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
    else {
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.orgTerraform.deleted') }, { root: true })
      commit('RESET_DEFAULT_EXTERNAL_BACKEND_STATE')
    }
  },

  async GET_DEFAULT_EXTERNAL_BACKEND ({ commit, dispatch, rootGetters: { orgCanonical } }) {
    commit('START_FETCH')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getExternalBackends(orgCanonical, { 'external_backend_default[eq]': true }) || {}
    if (data) {
      const externalBackend = _.get(data, '[0]', {})
      commit('SET_DEFAULT_EXTERNAL_BACKEND', ebMapping.remap(externalBackend))
    }
    if (errors) dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
    commit('STOP_FETCH')
  },

  async UPDATE_DEFAULT_EXTERNAL_BACKEND ({ commit, dispatch, rootGetters: { orgCanonical } }, { externalBackendId, config }) {
    commit('CLEAR_DEFAULT_EXTERNAL_BACKEND_ERRORS')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.updateExternalBackend(orgCanonical, externalBackendId, config) || {}
    if (data) commit('SET_DEFAULT_EXTERNAL_BACKEND', data)
    if (errors) {
      dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
      commit('SET_ERRORS', { errors })
    }
  },
}

export const mutations = {
  CLEAR_DEFAULT_EXTERNAL_BACKEND_ERRORS: CLEAR_ERRORS,
  RESET_DEFAULT_EXTERNAL_BACKEND_STATE: RESET_STATE,
  SET_ERRORS,

  SET_DEFAULT_EXTERNAL_BACKEND (state, defaultExternalBackend) {
    Vue.set(state, 'detail', defaultExternalBackend)
  },

  START_FETCH (state) {
    Vue.set(state, 'fetchInProgress', true)
  },

  STOP_FETCH (state) {
    Vue.set(state, 'fetchInProgress', false)
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
