import Vue from 'vue'
import i18n from '@/utils/plugins/i18n'
import { vuexMixin } from '@/utils/helpers'

export const initialState = {
  detail: null,
  options: null,
  errors: [],
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {}

const {
  actions: { GET_OWNER_OBJECT },
  mutations: { CLEAR_ERRORS, RESET_STATE, SET_ERRORS },
} = vuexMixin(initialState)

export const actions = {
  GET_OWNER_OBJECT,

  async CREATE_CREDENTIAL ({ commit, dispatch, rootGetters: { orgCanonical } }, { credential, $router }) {
    commit('CLEAR_CREDENTIAL_ERRORS')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.createCredential(orgCanonical, credential) || {}
    if (data) {
      commit('SET_CREDENTIAL', data)
      const content = i18n.t('alerts.success.credential.created', { credentialName: credential.name })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
      if ($router) $router.push({ name: 'credentials' })
    }
    if (errors) commit('SET_ERRORS', { errors })
  },

  async DELETE_CREDENTIAL ({ commit, dispatch, rootGetters: { orgCanonical } }, { credential, $router }) {
    commit('CLEAR_CREDENTIAL_ERRORS')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteCredential(orgCanonical, credential.canonical) || {}
    if (errors) commit('SET_ERRORS', { errors })
    else {
      commit('RESET_CREDENTIAL_STATE')
      const content = i18n.t('alerts.success.credential.deleted', { credentialName: credential.name })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
      if ($router) $router.push({ name: 'credentials' })
    }
  },

  async GET_CREDENTIAL ({ commit, rootGetters: { orgCanonical } }, { credentialCanonical }) {
    commit('CLEAR_CREDENTIAL_ERRORS')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getCredential(orgCanonical, credentialCanonical) || {}
    if (data) commit('SET_CREDENTIAL', data)
    if (errors) commit('SET_ERRORS', { errors })
  },

  async UPDATE_CREDENTIAL ({ commit, dispatch, rootGetters: { orgCanonical } }, { credential, successMessage }) {
    commit('CLEAR_CREDENTIAL_ERRORS')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.updateCredential(orgCanonical, credential) || {}
    if (data) {
      commit('SET_CREDENTIAL', await dispatch('GET_OWNER_OBJECT', { data, oldVal: credential }))
      const content = successMessage || i18n.t('alerts.success.credential.updated', { credentialName: credential.name })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
    }
    if (errors) commit('SET_ERRORS', { errors })
  },

  async GET_CREDENTIAL_OPTIONS ({ commit, rootGetters: { orgCanonical } }, { credentialCanonical, service }) {
    commit('CLEAR_CREDENTIAL_ERRORS')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getCredentialOptions(orgCanonical, credentialCanonical, service) || {}
    if (data) commit('SET_CREDENTIAL_OPTIONS', data)
    if (errors) commit('SET_ERRORS', { errors })
  },
}

export const mutations = {
  CLEAR_CREDENTIAL_ERRORS: CLEAR_ERRORS,
  RESET_CREDENTIAL_STATE: RESET_STATE,
  SET_ERRORS,

  SET_CREDENTIAL (state, credential) {
    Vue.set(state, 'detail', credential)
  },

  SET_CREDENTIAL_OPTIONS (state, options) {
    Vue.set(state, 'options', options)
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
