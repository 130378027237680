import Vue from 'vue'
import i18n from '@/utils/plugins/i18n'
import { vuexMixin } from '@/utils/helpers'

export const initialState = {
  detail: null,
  errors: [],
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {}

const {
  actions: { GET_OWNER_OBJECT },
  mutations: { CLEAR_ERRORS, RESET_STATE, SET_ERRORS },
} = vuexMixin(initialState)

export const actions = {
  GET_OWNER_OBJECT,

  async GET_INFRA_POLICY ({ commit, rootGetters: { orgCanonical } }, { infraPolicyCanonical }) {
    commit('CLEAR_INFRA_POLICY_ERRORS')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getInfraPolicy(orgCanonical, infraPolicyCanonical) || {}
    if (data) commit('SET_INFRA_POLICY', data)
    if (errors) commit('SET_ERRORS', { errors })
  },

  async CREATE_INFRA_POLICY ({ commit, dispatch, rootGetters: { orgCanonical } }, { infraPolicy }) {
    commit('CLEAR_INFRA_POLICY_ERRORS')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.createInfraPolicy(orgCanonical, infraPolicy) || {}
    if (data) {
      commit('SET_INFRA_POLICY', _.merge(data, infraPolicy))
      const content = i18n.t('alerts.success.infraPolicy.created', { infraPolicyName: data.name })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
    }
    if (errors) commit('SET_ERRORS', { errors })
  },

  async UPDATE_INFRA_POLICY ({ commit, dispatch, rootGetters: { orgCanonical } }, { infraPolicy, successMessage }) {
    commit('CLEAR_INFRA_POLICY_ERRORS')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.updateInfraPolicy(orgCanonical, infraPolicy) || {}
    if (data) {
      commit('SET_INFRA_POLICY', await dispatch('GET_OWNER_OBJECT', { data, oldVal: infraPolicy }))
      const content = successMessage || i18n.t('alerts.success.infraPolicy.updated', { infraPolicyName: data.name })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
    }
    if (errors) commit(`SET_ERRORS`, { errors })
  },

  async DELETE_INFRA_POLICY ({ commit, dispatch, rootGetters: { orgCanonical } }, { infraPolicy, $router }) {
    commit('CLEAR_INFRA_POLICY_ERRORS')

    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteInfraPolicy(orgCanonical, infraPolicy.canonical) || {}
    if (errors) commit('SET_ERRORS', { errors })
    else {
      commit('RESET_INFRA_POLICY_STATE')
      const content = i18n.t('alerts.success.infraPolicy.deleted', { infraPolicyName: infraPolicy.name })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
      if ($router) $router.replace({ name: 'infraPolicies' })
    }
  },
}

export const mutations = {
  CLEAR_INFRA_POLICY_ERRORS: CLEAR_ERRORS,
  RESET_INFRA_POLICY_STATE: RESET_STATE,
  SET_ERRORS,

  SET_INFRA_POLICY (state, infraPolicy) {
    Vue.set(state, 'detail', infraPolicy)
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
