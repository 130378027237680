import Vue from 'vue'
import { TerraformProvider } from '@/utils/classes'
import { arrayToObject, vuexMixin, alreadyFetched, populateEachElementImage } from '@/utils/helpers'
export const supportedProviders = ['aws', 'google', 'azurerm', 'azure', 'gcp', 'vsphere']
export const retryInfraImportIntervalTimer = 5000

export const requiredProviderFields = {
  aws: ['region'],
  google: ['region', 'project'],
  azurerm: ['environment', 'resourceGroupNames'],
  vsphere: ['server', 'allowUnverifiedSsl'],
}

export const initialState = {
  available: {
    resources: {},
    providers: {},
  },
  provider: {},
  providerCanonical: null,
  providerConfig: {},
  step: 1,
  lastActiveStep: 1,
  wizardConfig: {
    active: false,
    createStackOnly: true,
  },
  stack: null,
  project: null,
  entities: null,
  resourceIds: {},
  externalBackendConfig: null,
  variables: {},
  tags: [],
  errors: {},
  loading: {
    providerResources: false,
  },
  infraImportStatus: {
    stacks: {},
    projects: {},
  },
  showImportProgressModal: false,
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  list: (state) => (path) => Object.values(_.$get(state, path, [])),
  infraImportStatus: (state) => (canonicalOrStackRef, key = 'stacks') => _.$get(state.infraImportStatus[key], canonicalOrStackRef, { status: '' }).status,
  providerAbbreviation: (state) => state.provider.abbreviation === 'vSphere' ? 'VMWareVSphere' : state.provider.abbreviation,
}

const {
  mutations: { SET_ERRORS, CLEAR_ERRORS, RESET_STATE },
} = vuexMixin(initialState)

export const actions = {
  async GET_PROVIDERS ({ commit, rootGetters: { orgCanonical } }, { reqPage } = {}) {
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getTerraformProviders({ orgCanonical }, reqPage) || {}
    if (data) {
      const providers = _(data)
        .filter(({ canonical }) => !['template', 'vault'].includes(canonical))
        .map((provider) => ({
          ...provider,
          isSupported: supportedProviders.includes(provider.canonical),
        }))
        .sortBy('name')
        .sortBy(({ isSupported }) => !isSupported)
        .value()

      commit('SET_AVAILABLE_PROVIDERS', { providers })
    }
    if (errors) commit(`SET_ERRORS`, { key: 'providers', errors })
  },

  async GET_PROVIDER_BY_ID ({ commit, rootGetters: { orgCanonical }, state }, { providerId }) {
    const baseProps = state.available.providers[providerId]

    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getTerraformProvider({ orgCanonical, providerId }) || {}
    if (data) commit(`SET_PROVIDER`, { provider: data, baseProps })
    if (errors) commit(`SET_ERRORS`, { key: 'provider', errors })
  },

  async GET_PROVIDER_RESOURCES ({ commit, getters, rootGetters: { orgCanonical }, state }) {
    commit('CLEAR_ERRORS', 'providerResources')
    commit('SET_LOADING', { key: 'providerResources', loading: true })

    const payload = {
      abbreviation: getters.providerAbbreviation,
      body: _.$snakeCaseKeys(state.providerConfig),
      orgCanonical,
      providerId: state.provider.canonical,
    }

    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getInfraImportResources(payload) || {}
    if (data) commit(`SET_AVAILABLE_RESOURCES`, { resources: data })
    if (errors) commit(`SET_ERRORS`, { key: 'providerResources', errors })
    commit('SET_LOADING', { key: 'providerResources', loading: false })
  },

  async GET_PROVIDER_RESOURCE_BY_ID ({ commit, getters, rootGetters: { orgCanonical }, state }, { resourceId }) {
    const { cached } = alreadyFetched({ list: getters.list('available.resources'), id: resourceId })

    if (cached) return

    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getTerraformProviderResource({ orgCanonical, providerId: state.providerCanonical, resourceId }) || {}
    if (data) commit('SET_AVAILABLE_RESOURCE', { resourceId, resource: data })
    if (errors) commit(`SET_ERRORS`, { key: 'resource', errors })
  },

  async GET_PROVIDER_RESOURCE_IDS ({ commit, getters, rootGetters: { orgCanonical }, state }, { resourceCanonical, tags }) {
    const payload = {
      abbreviation: getters.providerAbbreviation,
      body: _.$snakeCaseKeys(state.providerConfig),
      orgCanonical,
      providerId: state.provider.canonical,
      resourceCanonical,
      tags,
    }

    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getInfraImportResource(payload) || {}

    if (data) commit('SET_RESOURCE_IDS', { resourceCanonical, ids: data })
    if (errors) commit(`SET_ERRORS`, { key: 'resourceIDs', errors })
  },

  async START_INFRA_IMPORT ({ commit, getters, state, rootGetters: { orgCanonical } }) {
    commit('CLEAR_ERRORS', 'infraImport')
    commit('RESET_IMPORT_STATUS')
    commit('SHOW_IMPORT_PROGRESS_MODAL', true)

    const providerAbbreviation = getters.providerAbbreviation
    const payload = populateCreateInfraImportPayload({ ...state, providerAbbreviation })
    const { errors } = await Vue.prototype.$cycloid.ydAPI.createInfraImport(orgCanonical, payload) || {}

    if (errors) commit('SET_ERRORS', { key: 'infraImport', errors })
  },

  async GET_STACK_INFRA_IMPORT_STATUS ({ commit, rootGetters: { orgCanonical } }, { stackRef }) {
    commit('CLEAR_ERRORS', 'stackInfraImportStatus')

    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getServiceCatalogImport(orgCanonical, stackRef) || {}
    if (data) commit('SET_INFRA_IMPORT_STATUS', { key: 'stacks', identifier: stackRef, data })
    if (errors) commit('SET_ERRORS', { key: 'stackInfraImportStatus', errors })
  },

  async GET_PROJECT_INFRA_IMPORT_STATUS ({ commit, rootGetters: { orgCanonical } }, { canonical }) {
    commit('CLEAR_ERRORS', 'projectInfraImportStatus')

    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getProjectImport(orgCanonical, canonical) || {}

    if (data) commit('SET_INFRA_IMPORT_STATUS', { key: 'projects', identifier: canonical, data })
    if (errors) commit('SET_ERRORS', { key: 'projectInfraImportStatus', errors })
  },

  async RETRY_STACK_INFRA_IMPORT ({ commit, rootGetters: { orgCanonical } }, { stackRef }) {
    commit('CLEAR_ERRORS', 'stackInfraImportStatus')

    const { errors } = await Vue.prototype.$cycloid.ydAPI.retryServiceCatalogImport(orgCanonical, stackRef) || {}

    if (errors) commit('SET_ERRORS', { key: 'stackInfraImportStatus', errors })
  },

  async RETRY_PROJECT_INFRA_IMPORT ({ commit, rootGetters: { orgCanonical } }, { canonical }) {
    commit('CLEAR_ERRORS', 'projectInfraImportStatus')

    const { errors } = await Vue.prototype.$cycloid.ydAPI.retryProjectImport(orgCanonical, canonical) || {}

    if (errors) commit('SET_ERRORS', { key: 'projectInfraImportStatus', errors })
  },
}

export const mutations = {
  CLEAR_ERRORS,
  RESET_STATE,
  SET_ERRORS,

  SET_PROVIDER_CANONICAL (state, canonical) {
    Vue.set(state, 'providerCanonical', canonical)
  },

  SET_STACK (state, stack) {
    Vue.set(
      state,
      'stack', {
        ...stack,
        ...(!_.$isEmpty(stack.dependencies) ? { dependencies: stack.dependencies.map((dependency) => ({ ref: dependency, required: true })) } : {}),
      },
    )
  },

  PREVIOUS_STEP (state) {
    state.step--
  },

  SET_WIZARD_CONFIG (state, wizardConfig) {
    Vue.set(state, 'wizardConfig', wizardConfig)
  },

  SET_PROJECT (state, project) {
    Vue.set(state, 'project', { ...project, config_repository_canonical: project?.configRepository?.canonical })
  },

  SET_PROVIDER (state, { provider, baseProps }) {
    Vue.set(state, 'provider', new TerraformProvider(baseProps, provider))
  },

  SET_PROVIDER_CONFIG (state, providerConfig) {
    Vue.set(state, 'providerConfig', providerConfig)
  },

  SET_EXTERNAL_BACKEND_CONFIG (state, eb) {
    Vue.set(state, 'externalBackendConfig', eb)
  },

  SET_ENTITIES (state, { entities = [], tags = [] }) {
    Vue.set(state, 'entities', entities)
    Vue.set(state, 'tags', tags)
  },

  SET_VARIABLES (state, variables) {
    Vue.set(state, 'variables', variables)
  },

  NEXT_STEP (state) {
    if (state.step - state.lastActiveStep === 0) state.lastActiveStep++
    state.step++
  },

  RESET_IMPORT_STATUS (state) {
    Vue.set(state, 'infraImportStatus', initialState.infraImportStatus)
  },

  SET_STEP (state, step) {
    Vue.set(state, 'step', step)
  },

  SET_AVAILABLE_RESOURCE (state, { resourceId, resource }) {
    const original = state.available.resources[resourceId] || {}
    const { image = null, category } = original

    Vue.set(state.available.resources, resourceId, { ...original, ...resource, image, category })
  },

  SET_AVAILABLE_RESOURCES (state, { resources }) {
    resources = populateEachElementImage(resources, 'resource')
    Vue.set(state.available, 'resources', { ...arrayToObject(resources, { key: 'canonical', retainKey: true }) })
  },

  SET_AVAILABLE_PROVIDERS (state, { providers }) {
    Vue.set(state.available, 'providers', arrayToObject(providers, { key: 'canonical', retainKey: true }))
  },

  SET_RESOURCE_IDS (state, { ids, resourceCanonical }) {
    Vue.set(state.resourceIds, resourceCanonical, ids)
  },

  SET_LOADING (state, { key, loading }) {
    Vue.set(state.loading, key, loading)
  },

  SET_INFRA_IMPORT_STATUS (state, { key, identifier, data }) {
    Vue.set(state.infraImportStatus[key], identifier, data)
  },

  SET_INFRA_IMPORT_IN_PROGRESS (state, inProgress) {
    Vue.set(state, 'infraImportInProgress', inProgress)
  },

  SHOW_IMPORT_PROGRESS_MODAL (state, inProgress) {
    Vue.set(state, 'showImportProgressModal', inProgress)
  },

  RESET_PROVIDER_CONFIG (state) {
    Vue.set(state, 'providerConfig', {})
  },
}

export function populateCreateInfraImportPayload ({
  stack,
  providerConfig,
  variables,
  project,
  externalBackendConfig,
  entities,
  providerAbbreviation,
  providerCanonical,
  tags,
}) {
  const configuration = _.pick(providerConfig, requiredProviderFields[providerCanonical])
  configuration.type = `CloudProvider${providerAbbreviation}Configuration`

  return {
    stack,
    configuration: _.$snakeCaseKeys(configuration),
    credential_canonical: _.$get(providerConfig, 'credentialCanonical', ''),
    ...(!_.$isEmpty(variables) ? { module_variables: variables } : {}),
    ...(!_.$isEmpty(project) ? { project } : {}),
    ...(!_.$isEmpty(project?.environment) ? { environment: project.environment } : {}),
    ...(!_.$isEmpty(externalBackendConfig) ? { external_backend: externalBackendConfig } : {}),
    ...(!_.$isEmpty(entities) ? { include: entities.map((entity) => entity.canonical) } : {}),
    ...(!_.$isEmpty(tags) ? { tags } : {}),
  }
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
