import systemErrors from './errors'
import { SLUG_LIMIT } from '@/utils/plugins/get-slug'

export default {
  en: {
    AlreadyExistsCanonical: 'The canonical is already in use',
    AlreadyExistsEmail: 'The email is already in use',
    AlreadyExistsEmailAddr: 'The email is already in use',
    AlreadyExistsEntity: 'Entity already exists',
    AlreadyExistsUsername: 'The username is already in use',
    InvalidCatalogRepositoryNotFound: `The repository couldn't be found or accessed. Check the URL and/or your credentials.`,
    InvalidConfigAnsibleSampleFileNotFound: `Ansible sample file couldn't be found`,
    InvalidConfigBranchDoesNotExist: `Default 'stacks' branch not found`,
    InvalidConfigCycloidYAMLDoesNotExist: `The .cycloid.yml file couldn't be found anywhere`,
    InvalidConfigPipelineFileNotFound: `Pipeline file couldn't be found`,
    InvalidConfigPipelineSampleFileNotFound: `Pipeline variables file couldn't be found`,
    InvalidConfigRepositoryNotFound: `The repository couldn't be found or accessed. Check the URL and/or your credentials.`,
    InvalidConfigTerraformSampleFileNotFound: `Terraform sample file couldn't be found`,
    InvalidFormatCanonical: `Invalid canonical format, should only contain alphanumerical and -_ special characters, cannot start with -_ and can not be longer than ${SLUG_LIMIT} characters`,
    InvalidFormatDataFromSrc: 'The logs may be malformatted, please verify that all of them have a non-empty "host" field',
    InvalidFormatEmail: 'Invalid email format',
    InvalidFormatEntity: 'Entity with invalid format',
    InvalidFormatHCL: `Parsing to HCL failed, please reconfigure and try again`,
    InvalidFormatID: 'The ID must be greater or equal than 0',
    InvalidFormatJSON: 'Invalid JSON format',
    InvalidFormatParamValue: 'Invalid parameter value',
    InvalidFormatPolicyOrganization: 'Invalid policy format',
    InvalidFormatURL: 'The URL must be in a valid format or empty',
    InvalidFormatYAML: 'Invalid YAML format',
    InvalidFormatPath: 'Invalid path',
    NotFoundEntity: 'Entity not found',
    NotFoundExternalBackend: `The external backend was not found`,
    NotFoundUser: 'User not found',
    UnauthenticatedInvalid: 'Invalid authentication',
    UnauthorizedNotEnoughPermissions: 'Not authorized',
    UnexpectedError: systemErrors.en.system,
  },
  es: {
    AlreadyExistsCanonical: 'El canónico ya está en uso',
    AlreadyExistsEmail: 'El correo electrónico ya está en uso',
    AlreadyExistsEmailAddr: 'El correo electrónico ya está en uso',
    AlreadyExistsEntity: 'La entidad ya existe',
    AlreadyExistsUsername: 'El nombre de usuario ya está en uso',
    InvalidCatalogRepositoryNotFound: `Repositorio no encontrado. Compruebe la URL o sus credenciales.`,
    InvalidConfigAnsibleSampleFileNotFound: 'Fichero Ansible de muestra no encontrado',
    InvalidConfigBranchDoesNotExist: `Rama por defecto 'stacks' no encontrada`,
    InvalidConfigCycloidYAMLDoesNotExist: 'Fichero .cycloid.yml no encontrado',
    InvalidConfigPipelineFileNotFound: 'Fichero pipeline no encontrado',
    InvalidConfigPipelineSampleFileNotFound: 'Fichero pipeline variables no encontrado',
    InvalidConfigRepositoryNotFound: 'Repositorio no encontrado. Compruebe la URL o sus credenciales.',
    InvalidConfigTerraformSampleFileNotFound: 'Fichero Terraform de muestra no encontrado',
    InvalidFormatCanonical: `Formato canónico no válido, solo debe contener caracteres alfanuméricos y -_ especiales, no puede comenzar con -_ y no puede tener más de ${SLUG_LIMIT} caracteres`,
    InvalidFormatDataFromSrc: 'Los logs pueden tener un formato erróneo, por favor verifique que contienen un campo "host" no nulo',
    InvalidFormatEmail: 'Formato de correo inválido',
    InvalidFormatEntity: 'Entidad con formato inválido',
    InvalidFormatHCL: `Error al analizar HCL, reconfigure e intente nuevamente`,
    InvalidFormatID: 'La ID debe ser mayor o igual que 0',
    InvalidFormatJSON: 'Formato del JSON inválido',
    InvalidFormatParamValue: 'Formato del parámetro inválido',
    InvalidFormatPolicyOrganization: 'Formato de policy inválido',
    InvalidFormatURL: 'La URL debe estar en formato válido o vacía',
    InvalidFormatYAML: 'Formato del YAML inválido',
    InvalidFormatPath: 'Camino inválido',
    NotFoundEntity: 'Entidad no encontrada',
    NotFoundExternalBackend: `No se encontró el backend externo`,
    NotFoundUser: 'Usuario no encontrado',
    UnauthenticatedInvalid: 'Autenticación inválida',
    UnauthorizedNotEnoughPermissions: 'No autorizado',
    UnexpectedError: systemErrors.es.system,
  },
  fr: {
    AlreadyExistsCanonical: 'Le canonique est déjà utilisé',
    AlreadyExistsEmail: `L'email est déjà utilisé`,
    AlreadyExistsEmailAddr: `L'email est déjà utilisé`,
    AlreadyExistsEntity: `L'entité existe déjà`,
    AlreadyExistsUsername: `Le nom d'utilisateur est déjà pris`,
    InvalidCatalogRepositoryNotFound: `Le repository n'a pas pu être trouvé. Vérifiez l'URL et/ou vos informations d'identification.`,
    InvalidConfigAnsibleSampleFileNotFound: `Le fichier d'exemple Ansible est introuvable`,
    InvalidConfigBranchDoesNotExist: `La branche "stacks" par défaut est introuvable`,
    InvalidConfigCycloidYAMLDoesNotExist: 'Le fichier .cycloid.yml est introuvable',
    InvalidConfigPipelineFileNotFound: 'Le fichier pipeline est introuvable',
    InvalidConfigPipelineSampleFileNotFound: 'Fichier de variables de pipeline introuvable',
    InvalidConfigRepositoryNotFound: `Le repository n'a pas pu être trouvé. Vérifiez l'URL et/ou vos informations d'identification.`,
    InvalidConfigTerraformSampleFileNotFound: `Le fichier d'exemple Terraform est introuvable`,
    InvalidFormatCanonical: `Format canonique non valide, ne doit contenir que des caractères alphanumériques et -_ spéciaux, ne peut pas commencer par -_ et ne peut pas dépasser ${SLUG_LIMIT} caractères`,
    InvalidFormatDataFromSrc: 'Les données peuvent être mal-formées, merci de vérifier que celles ci comportent bien le champs "message" et "host".',
    InvalidFormatEmail: `Format d'email invalide`,
    InvalidFormatEntity: 'Entité avec format invalide',
    InvalidFormatHCL: `Échec de la conversion vers HCL. Veuillez reconfigurer et réessayer`,
    InvalidFormatID: `L'ID doit être supérieur ou égal à 0`,
    InvalidFormatJSON: 'Format JSON invalide',
    InvalidFormatParamValue: 'Valeur de paramètre non valide',
    InvalidFormatPolicyOrganization: 'Format de policy invalide',
    InvalidFormatURL: `L'URL doit être dans un format valide ou vide`,
    InvalidFormatYAML: 'Format YAML invalide',
    InvalidFormatPath: 'Chemin invalide',
    NotFoundEntity: 'Entité non trouvée',
    NotFoundExternalBackend: `Le backend externe est introuvable`,
    NotFoundUser: 'Utilisateur non trouvé',
    UnauthenticatedInvalid: 'Authentification invalide',
    UnauthorizedNotEnoughPermissions: 'Pas autorisé',
    UnexpectedError: systemErrors.fr.system,
  },
}
