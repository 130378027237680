export const untranslatedRoutes = {
  cloudCostManagement: 'FinOps & GreenOps',
  cloudCostManagementTitle: 'FinOps & GreenOps: Cloud Cost and Carbon Footprint Management',
  infraImport: '@:InfraImport',
  infraPolicies: '@:InfraPolicies',
  infraPolicy: '@:InfraPolicy',
  jobs: 'Jobs',
  logs: 'Logs',
  pipeline: 'Pipeline',
  quota: '@:Quota',
  quotas: '@:Quotas',
  sandbox: 'Sandbox',
  workers: 'Workers',
}

export default {
  en: {
    /** Mapped via $route.meta.permissionsKey */
    403: {
      credentials: {
        create: '@:youDoNotHavePermissionsTo create a @:credential',
        details: '@:youDoNotHavePermissionsTo access this @:credential',
      },
      inventory: {
        create: '@:youDoNotHavePermissionsTo create a @:Resource',
      },
      resourcePools: {
        create: '@:youDoNotHavePermissionsTo create a @:ResourcePool',
      },
      stacks: {
        tools: '@:youDoNotHavePermissionsTo create or maintain a @:project',
      },
      organization: {
        settings: {
          appearance: {
            create: '@:youDoNotHavePermissionsTo create an @:appearance',
            details: '@:youDoNotHavePermissionsTo access this @:appearance',
          },
        },
      },
    },
    projectActivity: '@:Activity',
    apiKey: '@:APIKey',
    apiKeys: '@:APIKeys',
    catalogRepositories: '@:CatalogRepositories',
    catalogRepository: '@:CatalogRepository',
    configRepositories: '@:ConfigRepositories',
    configRepository: '@:ConfigRepository',
    cloudCostManagementAccounts: 'Accounts',
    cloudCostManagementAccountsAccount: 'Edit account',
    cloudCostManagementAccountsCredentials: 'Add new account',
    cloudCostManagementAccountsNew: 'Add new account',
    cloudCostManagementDashboard: '@:Dashboard',
    cloudCostManagementProviderDetail: 'In detail',
    cloudCostManagementSection: untranslatedRoutes.cloudCostManagement,
    cloudCostManagementSectionDescription: 'Gain visibility into your cloud expenses and environmental impact with our centralized hub for managing costs and monitoring carbon footprint.',
    cloudCostManagementTagMapping: 'Tag mapping',
    cloudCostManagementWelcome: 'Welcome',
    credential: '@:Credential',
    credentials: '@:Credentials',
    dashboard: '@:Dashboard',
    dashboardDescription: 'Access your favourite projects and metrics, or keep an eye on recent activity.',
    environment: '@:Environment',
    environmentActivity: '@:Activity',
    environmentConfig: '@:configuration',
    environmentMetrics: '@:Metrics',
    projectEnvironments: '@:Environments',
    events: '@:Events',
    formsEditor: 'StackForms Editor',
    infraImportDescription: 'Automate stack creation by generating infra-as-code from an existing infrastructure.',
    infraView: 'Infrastructure',
    infraTools: 'Tools',
    inventory: '@:Inventory',
    inventoryResource: 'Inventory resource',
    inventoryResourceDetails: 'Inventory resource details',
    inventoryResourceJson: 'Inventory resource JSON',
    login: 'Login',
    marketplaceNewOrganization: 'New @:Organization',
    member: '@:Member',
    members: '@:Members',
    membersInvite: 'Invite @:Members',
    MFAInvalidLoginLink: 'Invalid login link',
    MFALoginLinkNotice: 'Check your email',
    monitoring: 'Monitoring',
    projectConfigElasticSearch: 'Elastic Search logs configuration',
    projectConfigInfraView: 'Add Terraform State file configuration',
    observabilitySection: 'Observability',
    observabilitySectionDescription: `Keep an eye on what's happening inside your organization.`,
    organization: '@:Organization',
    organizations: '@:Organizations',
    orgSettings: 'Settings',
    orgSettingsAppearance: 'Custom @:appearance',
    orgSettingsTerraform: '@:externalBackends.RemoteTfBackend',
    pendingInvites: 'Invitations',
    pipelinesOverview: 'Pipelines Overview',
    privacy: 'Privacy Policy',
    profile: 'Profile',
    profileDescription: 'Update your personal details and preferences.',
    project: '@:Project',
    projectConfiguration: '@:Settings',
    projects: 'All @:Projects',
    projectsSection: '@:Projects',
    projectMetrics: '@:Metrics',
    resource: '@:Resource',
    resourcesSection: '@:Resources',
    resourcesSectionDescription: 'View, analyze and manage all your resources.',
    resourcePool: '@:ResourcePool',
    resourcePools: '@:ResourcePools',
    role: '@:Role',
    roles: '@:Roles',
    securitySection: 'Security',
    securitySectionDescription: 'Secure your projects and infrastructure, and ensure best practices across your organization.',
    stack: '@:Stack',
    stackFromTemplate: 'New @:Stack from template',
    stacks: 'All @:Stacks',
    stacksSection: '@:Stacks',
    stacksSectionDescription: 'Organize your infrastructure-as-code into reusable and configurable stacks, ensuring governance and empowering your teams.',
    status: 'Status',
    team: '@:Team',
    teams: '@:Teams',
    terms: 'Terms of Service',
    userAccount: 'Account',
    userEmails: 'Emails',
    userSecurity: 'Password and authentication',
    ...untranslatedRoutes,
    beta: {
      resourcesSection: '@:Resources',
      dev: 'Dev pages are always beta',
    },
  },
  es: {
    /** Mapped via $route.meta.permissionsKey */
    403: {
      credentials: {
        create: '@:youDoNotHavePermissionsTo crear una @:credential',
        details: '@:youDoNotHavePermissionsTo acceder a esta @:credential',
      },
      inventory: {
        create: '@:youDoNotHavePermissionsTo crear una @:Resource',
      },
      resourcePools: {
        create: '@:youDoNotHavePermissionsTo crear una @:ResourcePool',
      },
      stacks: {
        tools: '@:youDoNotHavePermissionsTo crear o mantener un @:project',
      },
      organization: {
        settings: {
          appearance: {
            create: '@:youDoNotHavePermissionsTo crear una @:appearance',
            details: '@:youDoNotHavePermissionsTo acceder a esta @:appearance',
          },
        },
      },
    },
    projectActivity: '@:Activity',
    apiKey: '@:APIKey',
    apiKeys: '@:APIKeys',
    catalogRepositories: '@:CatalogRepositories',
    catalogRepository: '@:CatalogRepository',
    configRepositories: '@:ConfigRepositories',
    configRepository: '@:ConfigRepository',
    cloudCostManagementAccounts: 'Cuentas',
    cloudCostManagementAccountsCredentials: 'Añadir nueva cuenta',
    cloudCostManagementAccountsNew: 'Añadir nueva cuenta',
    cloudCostManagementAccountsAccount: 'Editar cuenta',
    cloudCostManagementDashboard: '@:Dashboard',
    cloudCostManagementProviderDetail: 'En detalles',
    cloudCostManagementSection: untranslatedRoutes.cloudCostManagement,
    cloudCostManagementSectionDescription: 'Obtenga visibilidad sobre sus gastos en la nube y su impacto ambiental con nuestro centro centralizado para la gestión de costos y la huella de carbono.',
    cloudCostManagementTagMapping: 'Mapeo de tags',
    cloudCostManagementWelcome: 'Bienvenido',
    credential: '@:Credential',
    credentials: '@:Credentials',
    dashboard: '@:Dashboard',
    dashboardDescription: 'Acceda a sus proyectos y métricas favoritas, o mantenga un ojo en la actividad reciente.',
    environment: '@:Environment',
    environmentActivity: '@:Activity',
    environmentConfig: '@:configuration',
    environmentMetrics: '@:Metrics',
    projectEnvironments: '@:Environments',
    events: '@:Events',
    formsEditor: 'Editor de StackForms',
    infraImportDescription: 'Automatice la creación de stacks generando infraestructura como código a partir de una infraestructura existente.',
    infraView: 'Infraestructura',
    infraTools: 'Herramientas',
    inventory: '@:Inventory',
    inventoryResource: 'Recurso de inventario',
    inventoryResourceDetails: 'Detalles del recurso de inventario',
    inventoryResourceJson: 'Recurso de inventario JSON',
    login: 'Identificación',
    marketplaceNewOrganization: 'Nueva @:Organization',
    member: '@:Member',
    members: '@:Members',
    membersInvite: 'Invitar @:Members',
    MFAInvalidLoginLink: 'Enlace de inicio de sesión no válido',
    MFALoginLinkNotice: 'Revise sus correos electrónicos',
    monitoring: 'Monitoring',
    projectConfigElasticSearch: 'Configuración de los logs de Elastic Search',
    projectConfigInfraView: 'Agregar configuración de archivo de estado de Terraform',
    observabilitySection: 'Observabilidad',
    observabilitySectionDescription: `Esté atento a lo que sucede dentro de su organización.`,
    organization: '@:Organization',
    organizations: '@:Organizations',
    orgSettings: 'Ajustes',
    orgSettingsAppearance: '@:Appearance personalizada',
    orgSettingsTerraform: '@:externalBackends.RemoteTfBackend',
    pendingInvites: 'Invitaciones',
    pipelinesOverview: 'Resumen de Pipelines',
    privacy: 'Política de Privacidad',
    profile: 'Perfil',
    profileDescription: 'Actualice sus datos personales y preferencias.',
    project: '@:Project',
    projectConfiguration: '@:Settings',
    projects: 'Todo los @:Projects',
    projectsSection: '@:Projects',
    projectMetrics: '@:Metrics',
    resource: '@:Resource',
    resourcesSection: '@:Resources',
    resourcesSectionDescription: 'Visualiza, analiza y gestiona todos tus recursos.',
    resourcePool: '@:ResourcePool',
    resourcePools: '@:ResourcePools',
    role: '@:Role',
    roles: '@:Roles',
    securitySection: 'Seguridad',
    securitySectionDescription: 'Proteja sus proyectos e infraestructura y garantice las mejores prácticas en toda su organización.',
    stack: '@:Stack',
    stackFromTemplate: 'Nueva @:Stack de plantilla',
    stacks: 'Todos los @:Stacks',
    stacksSection: '@:Stacks',
    stacksSectionDescription: 'Organice su infraestructura como código en stacks reutilizables y configurables, asegurando el control y empoderando a sus equipos.',
    status: 'Estado',
    team: '@:Team',
    teams: '@:Teams',
    terms: 'Términos de Servicio',
    userAccount: 'Cuenta',
    userEmails: 'Correos electrónicos',
    userSecurity: 'Contraseña y autenticación',
    ...untranslatedRoutes,
    beta: {
      resourcesSection: '@:Resources',
      dev: 'Dev pages are always beta',
    },
  },
  fr: {
    /** Mapped via $route.meta.permissionsKey */
    403: {
      credentials: {
        create: '@:youDoNotHavePermissionsTo créer un @:credential',
        details: '@:youDoNotHavePermissionsTo accéder à cet @:credential',
      },
      inventory: {
        create: '@:youDoNotHave2PermissionsTo créer un @:Resource',
      },
      resourcePools: {
        create: '@:youDoNotHave2PermissionsTo créer un @:ResourcePool',
      },
      stacks: {
        tools: '@:youDoNotHavePermissionsTo créer ou maintenir un @:project',
      },
      organization: {
        settings: {
          appearance: {
            create: '@:youDoNotHavePermissionsTo créer une @:appearance',
            details: '@:youDoNotHavePermissionsTo accéder à cet @:appearance',
          },
        },
      },
    },
    projectActivity: '@:Activity',
    apiKey: '@:APIKey',
    apiKeys: '@:APIKeys',
    catalogRepositories: '@:CatalogRepositories',
    catalogRepository: '@:CatalogRepository',
    configRepositories: '@:ConfigRepositories',
    configRepository: '@:ConfigRepository',
    cloudCostManagementAccounts: 'Comptes',
    cloudCostManagementAccountsCredentials: 'Ajouter un nouveau compte',
    cloudCostManagementAccountsNew: 'Ajouter un nouveau compte',
    cloudCostManagementAccountsAccount: 'Editer un compte',
    cloudCostManagementDashboard: '@:Dashboard',
    cloudCostManagementProviderDetail: 'En détail',
    cloudCostManagementSection: untranslatedRoutes.cloudCostManagement,
    cloudCostManagementSectionDescription: `Obtenez une visibilité sur vos dépenses liées au cloud et leur impact environnemental grâce à notre centre de gestion centralisé pour les coûts et l'empreinte carbone.`,
    cloudCostManagementTagMapping: 'Association des tags',
    cloudCostManagementWelcome: 'Bienvenue',
    credential: '@:Credential',
    credentials: '@:Credentials',
    dashboard: '@:Dashboard',
    dashboardDescription: 'Accédez à vos projets et indicateurs préférés, ou gardez un œil sur les récentes activités.',
    environment: '@:Environment',
    environmentActivity: '@:Activity',
    environmentConfig: '@:configuration',
    environmentMetrics: '@:Metrics',
    projectEnvironments: '@:Environments',
    events: '@:Events',
    formsEditor: 'Éditeur StackForms',
    infraImportDescription: `Automatisez la création de stacks en générant une infra-as-code à partir d'une infrastructure existante.`,
    infraView: 'Infrastructure',
    infraTools: 'Outils',
    inventory: '@:Inventory',
    inventoryResource: `Ressource d'inventaire`,
    inventoryResourceDetails: `Détails de la ressource d'inventaire`,
    inventoryResourceJson: `Ressource d'inventaire JSON`,
    login: 'Identification',
    marketplaceNewOrganization: 'Nouvelle @:Organization',
    member: '@:Member',
    members: '@:Members',
    membersInvite: 'Inviter des @:Members',
    MFAInvalidLoginLink: 'Lien de connexion invalide',
    MFALoginLinkNotice: 'Vérifiez vos e-mails',
    monitoring: 'Monitoring',
    projectConfigElasticSearch: 'Configuration des logs Elastic Search',
    projectConfigInfraView: `Ajouter la configuration du fichier d'état Terraform`,
    observabilitySection: 'Observabilité',
    observabilitySectionDescription: `Gardez un œil sur ce qui se passe au sein de votre organisation.`,
    organization: '@:Organization',
    organizations: '@:Organizations',
    orgSettings: 'Réglages',
    orgSettingsAppearance: '@:Appearance personnalisée',
    orgSettingsTerraform: '@:externalBackends.RemoteTfBackend',
    pendingInvites: 'Invitations',
    pipelinesOverview: 'Aperçu des Pipelines',
    privacy: 'Politique de Confidentialité',
    profile: 'Profil',
    profileDescription: 'Mettez à jour vos données personnelles et vos préférences.',
    project: 'Projet',
    projectConfiguration: '@:Settings',
    projects: 'Tous les @:Projects',
    projectsSection: '@:Projects',
    projectMetrics: '@:Metrics',
    resource: '@:Resource',
    resourcesSection: '@:Resources',
    resourcesSectionDescription: 'Visualisez, analysez et gérez toutes vos ressources.',
    resourcePool: '@:ResourcePool',
    resourcePools: '@:ResourcePools',
    role: '@:Role',
    roles: '@:Roles',
    securitySection: 'Sécurité',
    securitySectionDescription: 'Sécurisez vos projets et votre infrastructure, et assurez les meilleures pratiques dans toute votre organisation.',
    stack: '@:Stack',
    stackFromTemplate: `Nouvelle @:Stack à partir d'un modèle`,
    stacks: 'Tous les @:Stacks',
    stacksSection: '@:Stacks',
    stacksSectionDescription: 'Organisez votre infrastructure en tant que code en stacks réutilisables et configurables, garantissant la gouvernance et responsabilisant vos équipes.',
    status: 'Statut',
    team: '@:Team',
    teams: '@:Teams',
    terms: `Conditions d'utilisation`,
    userAccount: 'Compte',
    userEmails: 'E-mails',
    userSecurity: 'Mot de passe et authentification',
    ...untranslatedRoutes,
    beta: {
      resourcesSection: '@:Resources',
      dev: 'Dev pages are always beta',
    },
  },
}
