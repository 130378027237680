import Vue from 'vue'
import VueRouter from 'vue-router'
import globalGuards from './globalGuards'
import errorHandler from './errorHandler'
import routes from './routes'
import store from '@/store'

const isTesting = process.env.NODE_ENV === 'test'

if (!isTesting) Vue.use(VueRouter)

const router = new VueRouter({
  mode: isTesting ? 'abstract' : 'history',
  base: '/',
  linkActiveClass: 'router-link-active',
  routes,
})

const { beforeEach, afterEach } = globalGuards(store)
beforeEach.forEach((params) => router.beforeEach(params))
afterEach.forEach((params) => router.afterEach(params))

router.onError(errorHandler)

/** This allows us to use Vue.prototype.$cyRouter in the store 🎉 */
Vue.use({ install (Vue) { Vue.prototype.$cyRouter = router } })

export default router
