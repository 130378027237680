import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

// Icons //
import Azure from '@/icons/azure'
import BarChart from '@/icons/bar-chart'
import DoubleArrowDown from '@/icons/double-arrow-down'
import DoubleArrowUp from '@/icons/double-arrow-up'
import FolderConfig from '@/icons/folder-config'
import GitHub from '@/icons/github'
import Google from '@/icons/google'
import InfraImport from '@/icons/infra-import'
import Microsoft from '@/icons/microsoft'
import NumberChart from '@/icons/number-chart'
import Ripple from 'vuetify/lib/directives/ripple'
import Saml from '@/icons/saml'
import Ssh from '@/icons/ssh'

Vue.use(Vuetify, {
  directives: {
    // This is needed, as described here:
    // https://github.com/vuetifyjs/vuetify/issues/12224#issuecomment-703061437
    // to bypass an apparent Vuetify bug: ```[Vue warn]: Failed to resolve directive: ripple
    // (found in <CyDataTableYdApi> at src/components/data-table-yd-api.vue)```
    Ripple,
  },
})

export const options = {
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: '#253449',
        secondary: '#1c9797',
        accent: '#f08b02',
        info: '#1c9797',
        success: '#51af45',
        error: '#e6213c',
        warning: '#fbbc0f',
        darkgrey: '#838589',
        appbg: '#fafafa',
      },
      light: {
        primary: '#253449',
        secondary: '#1c9797',
        accent: '#f08b02',
        info: '#1c9797',
        success: '#51af45',
        error: '#e6213c',
        warning: '#fbbc0f',
        darkgrey: '#838589',
        appbg: '#fafafa',
      },
    },
  },
  icons: {
    iconfont: 'mdi',
    values: {
      iconfont: 'mdi',
      // general icons
      barChart: { component: BarChart },
      doubleArrowDown: { component: DoubleArrowDown },
      doubleArrowUp: { component: DoubleArrowUp },
      folderConfig: { component: FolderConfig },
      infraImport: { component: InfraImport },
      numberChart: { component: NumberChart },
      ssh: { component: Ssh },
      // SSO provider icons
      azuread: { component: Azure },
      github: { component: GitHub },
      google: { component: Google },
      microsoft: { component: Microsoft },
      saml: { component: Saml },
    },
  },
}

export default new Vuetify(options)
