import { typeOf, throwBetterError } from '@/utils/helpers'
import chalk from 'chalk'
import _ from 'lodash' // eslint-disable-line no-restricted-imports

const ERROR = {
  EMPTY_OPTIONS: () => new Error('Options should not be empty, remove the empty options object.'),
  INVALID_OPTIONS: ({ ...invalidOptions }, { ...corrections } = {}) => new Error([
    `Invalid ${_.keys(invalidOptions).length === 1 ? 'option' : 'options'} found:\n`,
    ..._(invalidOptions)
      .keys()
      .map((key) => corrections?.[key]
        ? `\n\t- rename: ${chalk.magenta(key)} to ${chalk.green(corrections?.[key])}`
        : `\n\t- remove: ${chalk.magenta(key)}`)
      .value(),
  ].join('')),
  INVALID_PARAM_TYPE: (args, index, expectedType) => new Error([
    `Invalid argument[${index}], expected type "${expectedType}"`,
    `but received type "${typeOf(args[index])}"`,
  ].join('')),
}

export const lodashMixin = {
  /** Flattens an Object to replace each key/hierarchy with a singular flat path
   *
   * @returns Object with keys as paths
   *    @example
   *      _.$flattenObject({ a: { b: {c: 1, d: [3] } } })
   *      // returns { 'a.b.c': 1, 'a.b.d[0]' = 3 }
   */
  $flattenObject (obj = {}) {
    const result = {}

    const flatten = (collection, prefix = '', suffix = '') => {
      _.forEach(collection, (value, key) => {
        const path = `${prefix}${key}${suffix}`

        if (_.isArray(value)) flatten(value, `${path}[`, ']')
        else if (_.isPlainObject(value)) flatten(value, `${path}.`)
        else result[path] = value
      })
    }

    flatten(obj)
    return result
  },
  /** Returns the `obj[path]` if the value is truthy
   * Else returns the `alternativeValue`
   */
  $get (obj, path, alternativeValue) {
    return _.get(obj, path) || alternativeValue
  },
  /** Finds the first populated value, given an Object and a list of valid keys to check
   *
   * @example
   *   const obj = { a: '', b: 2, c: [1, 2, 3], d: 'apple' }
   *   _.$getFirstPopulatedValue(obj, ['a', 'b', 'd'])
   *   // returns 2
   *   _.$getFirstPopulatedValue(obj, ['a', 'd'])
   *   // returns 'apple'
   *   _.$getFirstPopulatedValue(obj, ['a', 'e'])
   *   // returns null
   */
  $getFirstPopulatedValue (obj, validKeys) {
    const sortedValues = _.map(validKeys, (key) => obj[key])
    return _.find(sortedValues) || null
  },
  $getListFromArray (theArray, { conjunction = '&', boldItems = false } = {}) {
    const array = _.cloneDeep(theArray)
    const isValidArray = _.every(array, _.isString) && !_.isEmpty(array) && _.isArray(array)
    if (!isValidArray) {
      console.warn('[Lodash mixin $getListFromArray] was not passed a valid param. The param must be an Array with all of its items as Strings', array)
      return ''
    }
    const finalItem = array.pop()
    const wrapItem = (item) => boldItems ? `<b>${item}</b>` : item
    return array.length
      ? `${array.map((item) => wrapItem(item)).join(', ')} ${conjunction} ${wrapItem(finalItem)}`
      : `${wrapItem(finalItem)}`
  },
  /** Throws errors when a Function is passed invalid options.
   *
   * @param {Function}            callee  - The Function itself
   * @param {Function.arguments}  args    - The Function's arguments
   *
   * @example // No options
   * function getUser (name, age)
   *   _.$handleInvalidOptions(getUser, arguments)
   *   return `Hello my name is ${name}, I am ${age} years old.`
   * }
   * @example // Has options (no corrections)
   * function getUser (name, { age = 99, ...invalidOptions })
   *   _.$handleInvalidOptions(getUser, arguments, { invalidOptions, calleeName: 'getUser' })
   *   return `Hello my name is ${name}, I am ${age} years old.`
   * }
   * @example // Has options (with corrections)
   * function getUser (name, { age = 99, ...invalidOptions })
   *   _.$handleInvalidOptions(getUser, arguments, {
   *     invalidOptions,
   *     corrections: { year: 'age' } // ! { [commonlyMistakenKey], 'correctKeyName' }
   *   })
   *   return `Hello my name is ${name}, I am ${age} years old.`
   * }
   *
   * @throws `No params were passed`                      - _when $handleInvalidOptions was not passed any params._
   * @throws `Invalid argument[index], expected type...`  - _when any $handleInvalidOptions param type is invalid._
   * @throws `Options should not be empty...`             - _when an empty options Object is passed._
   * @throws `Invalid options found...`                   - _when unexpected entries were found on the options Object._
   */
  $handleInvalidOptions (callee, args, { corrections = {}, invalidOptions = {} } = {}) {
    const extraOptions = _.omit(arguments[2], ['corrections', 'invalidOptions'])
    const internalError = _({
      emptyArgs: _.isEmpty(arguments) && new Error('No params were passed.'),
      invalid1stParam: !_.isFunction(arguments[0]) && ERROR.INVALID_PARAM_TYPE(arguments, 0, 'function'),
      invalid2ndParam: !_.isArguments(arguments[1]) && ERROR.INVALID_PARAM_TYPE(arguments, 1, 'arguments'),
      invalid3rdParam: !_.isPlainObject(arguments[2]) && ERROR.INVALID_PARAM_TYPE(arguments, 2, 'object'),
      emptyOptions: _.isEqual(_.last(arguments), {}) && ERROR.EMPTY_OPTIONS(),
      hasExtraOptions: !_.isEmpty(extraOptions) && ERROR.INVALID_OPTIONS(extraOptions),
    }).pickBy().values().find()
    if (internalError) {
      internalError.name = 'Params Error'
      return throwBetterError(internalError, _.$handleInvalidOptions)
    }

    const calleeOptions = _.isPlainObject(_.last(args)) ? _.last(args) : null
    const externalError = _({
      emptyOptions: _.isEqual(calleeOptions, {}) && ERROR.EMPTY_OPTIONS(),
      hasExtraOptions: !_.isEmpty(invalidOptions) && ERROR.INVALID_OPTIONS(invalidOptions, corrections),
    }).pickBy().values().find()
    if (externalError) {
      externalError.name = 'Params Error'
      return throwBetterError(externalError, _.$handleInvalidOptions, { calleeName: callee?.name })
    }
  },
  $hasAll (obj, keysToCheck) {
    if (typeOf(obj) !== 'object') console.warn(`[Lodash mixin $hasAll] was not passed a valid 1st param, it must be an Object`, obj)
    if (!_.isArray(keysToCheck)) console.warn(`[Lodash mixin $hasAll] was not passed a valid 2nd param, it must be an Array`, keysToCheck)
    if (typeOf(obj) !== 'object' || !_.isArray(keysToCheck)) return false
    return keysToCheck.every((key) => _.has(obj, key))
  },
  $hasAny (obj, keysToCheck) {
    if (typeOf(obj) !== 'object') console.warn(`[Lodash mixin $hasAny] was not passed a valid 1st param, it must be an Object`, obj)
    if (!_.isArray(keysToCheck)) {
      console.warn(`[Lodash mixin $hasAny] was not passed a valid 2nd param, it must be an Array`, keysToCheck)
      return false
    }
    return keysToCheck.some((key) => _.has(obj, key))
  },
  $isEmpty (value) {
    const canBeEmptyChecked = ['object', 'array', 'string', 'null', 'undefined']
    return canBeEmptyChecked.includes(typeOf(value)) && _.isEmpty(value)
  },
  /** Returns true if not an Array, Object, null nor undefined
   * https://lodash.com/docs/#isObject
   */
  $isPrimitive (value) {
    return !_.isObject(value) && !_.isNil(value)
  },
  $snakeCaseKeys (object) {
    return _.mapKeys(object, (_value, key) => _.snakeCase(key))
  },
  $camelCaseKeys (object) {
    return _.mapKeys(object, (_value, key) => _.camelCase(key))
  },
  $pascalCase (string) {
    return _.chain(string).camelCase().upperFirst().value()
  },
  /** Pauses code for x milliseconds */
  $pause (milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds))
  },
  /** Lowercases everything in a string apart from the very first character.
   *
   * @param {String} [text=''] - The text
   *
   * @example
   * ```js
   * _.$sentenceCase('the BIG blue Whale... OMG!')
   * // returns 'The big blue whale... omg!'
   * ```
  */
  $sentenceCase (text = '') {
    return _.upperFirst(text.toLowerCase())
  },
  $sortObjKeys (object) {
    return _(object).toPairs().sortBy(0).fromPairs().value()
  },
  $randomFromList (array = []) {
    if (!_.isArray(array) || _.isEmpty(array)) return console.warn(`[Lodash mixin $randomFromList] was not passed a valid param, it must be an Array`, array)
    return array[_.random(array.length - 1)]
  },
}

_.mixin(lodashMixin, { chain: true })
window._ = _

export default {
  install (Vue) {
    Vue.mixin({
      computed: {
        _ () { // eslint-disable-line vue/no-unused-properties
          return _
        },
      },
    })
  },
}
