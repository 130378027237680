import Vue from 'vue'
import i18n from '@/utils/plugins/i18n'
import store from '@/store'
import { AppError, types as errTypes } from '@/utils/config/errors'
import { throwBetterError } from '@/utils/helpers'

export default function errorHandler (err) {
  if (Vue.prototype.$isStagingOrDev || Vue.prototype.$isEEnv) {
    throwBetterError(err, errorHandler, { calleeName: 'router.onErrorHandler' })
  }

  store.dispatch('alerts/SHOW_ALERT', {
    type: 'error',
    content: [AppError.fromError(
      err,
      'router.onErrorHandler: Captured error',
      i18n.t('errors.system'),
      errTypes.SYS,
    )],
  })
}
