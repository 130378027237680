import Vue from 'vue'
import i18n from '@/utils/plugins/i18n'
import { vuexMixin } from '@/utils/helpers'

export const initialState = {
  detail: {
    name: '',
    description: '',
    rules: [],
  },
  errors: {
    role: [],
  },
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  role: (state) => state.detail,
}

const {
  mutations: { CLEAR_ERRORS, RESET_STATE, SET_ERRORS },
} = vuexMixin(initialState)

export const actions = {
  async CREATE_ROLE ({ commit, dispatch, rootGetters: { orgCanonical } }, { $router, role, isDuplicating = false }) {
    commit('CLEAR_ROLE_ERRORS', 'role')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.createOrgRole(orgCanonical, role) || {}
    if (errors) {
      commit('SET_ERRORS', { key: 'role', errors })
      dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
    } else {
      commit('SET_ROLE', data)
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t(`alerts.success.role.${isDuplicating ? 'duplicated' : 'created'}`) }, { root: true })
      $router.push({ name: 'roles' })
    }
  },

  async DELETE_ROLE ({ commit, dispatch, rootGetters: { orgCanonical } }, { $router, roleCanonical }) {
    commit('CLEAR_ROLE_ERRORS', 'role')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteOrgRole(orgCanonical, roleCanonical) || {}
    if (errors) {
      commit('SET_ERRORS', { key: 'role', errors })
      dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
    } else {
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.role.deleted') }, { root: true })
      if (!_.isEmpty($router)) $router.push({ name: 'roles' })
    }
  },

  async GET_ROLE ({ commit, rootGetters: { orgCanonical } }, { roleCanonical }) {
    commit('CLEAR_ROLE_ERRORS', 'role')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getOrgRole(orgCanonical, roleCanonical) || {}
    if (data) commit('SET_ROLE', { ...data })
    if (errors) commit('SET_ERRORS', { key: 'role', errors })
  },

  async UPDATE_ROLE ({ commit, dispatch, rootGetters: { orgCanonical } }, { $router, roleCanonical, role }) {
    commit('CLEAR_ROLE_ERRORS', 'role')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.updateOrgRole(orgCanonical, roleCanonical, role) || {}
    if (errors) commit('SET_ERRORS', { key: 'role', errors })
    else {
      commit('SET_ROLE', { ...data })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.role.updated') }, { root: true })
      if (!_.isEmpty($router)) $router.push({ name: 'roles' })
    }
  },
}

export const mutations = {
  CLEAR_ROLE_ERRORS: CLEAR_ERRORS,
  RESET_ROLE_STATE: RESET_STATE,
  SET_ERRORS,

  SET_ROLE (state, role) {
    Vue.set(state, 'detail', role)
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
