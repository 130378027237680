import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment
import i18n from '@/utils/plugins/i18n.js'
import { aws, azurerm, flexibleengine, google, openstack, scaleway, vault, vmware } from '@/assets/images'
import { formatAmount } from '@/utils/helpers'

export const credentialTypesAllowed = ['aws', 'azure_storage', 'gcp']

export const chartLegendColors = ['#1C9797', '#54BABA', '#167979']
export const dataTypeColors = {
  co2e: '#EB53B0',
  cost: '#54BABA',
  kwh: '#6A8EF3',
}

// When retrieving credential options data, this is how tables of a default GCP project starts with,
// and it's currently the only way we have to get the default project ID of a GCP billing dataset.
export const defaultGCPTableID = 'gcp_billing_export_resource_v1'

export const graphTypes = ['line', 'bar', 'stacked-bar']

export const graphTypesConfig = {
  line: {
    xAxis: {
      boundaryGap: false,
    },
    grid: {
      left: 24,
    },
    series: {
      type: 'line',
      showSymbol: false,
    },
  },
  bar: {
    xAxis: {
      boundaryGap: true,
    },
    series: {
      type: 'bar',
      stack: false,
      barMaxWidth: '10%',
      z: 5,
    },
    tooltip: {
      axisPointer: {
        type: 'shadow',
        color: '#90A5C1',
        z: 1,
      },
    },
  },
  'stacked-bar': {
    xAxis: {
      boundaryGap: true,
    },
    series: {
      type: 'bar',
      stack: 'one',
      barMaxWidth: '90%',
      z: 5,
    },
    tooltip: {
      axisPointer: {
        type: 'shadow',
        color: '#90A5C1',
        z: 1,
      },
    },
  },
}

// ! .logo is only used for CyCondensedAvatar
// All other logos use .credentialType passed to CyIconCredential
export const providersExtraInfo = {
  aws: {
    credentialType: 'aws',
    displayName: 'AWS',
    displayNameFull: 'Amazon Web Services',
    logo: aws.logo,
    canonical: 'aws',
    providerCanonical: 'aws',
    remoteTfStateEngine: 'AWSStorage',
  },
  azure: {
    credentialType: 'azure_storage',
    displayName: 'Azure',
    displayNameFull: 'Microsoft Azure',
    logo: azurerm.logo,
    canonical: 'azurerm',
    providerCanonical: 'azure',
    remoteTfStateEngine: 'AzureStorage',
  },
  gcp: {
    credentialType: 'gcp',
    displayName: 'GCP',
    displayNameFull: 'Google Cloud Platform',
    logo: google.logo,
    canonical: 'google',
    providerCanonical: 'gcp',
    remoteTfStateEngine: 'GCPCostStorage',
  },
  scw: {
    credentialType: 'scw',
    displayName: 'Scaleway',
    displayNameFull: 'Scaleway',
    logo: scaleway.logo,
    canonical: 'scw',
    providerCanonical: 'scw',
  },
  vault: {
    displayName: 'Vault',
    displayNameFull: 'Vault',
    logo: vault.logo,
    providerCanonical: 'vault',
  },
  vmware: {
    displayName: 'VMW',
    displayNameFull: 'VMware',
    logo: vmware.logo,
    providerCanonical: 'vmware',
  },
  flexibleengine: {
    displayName: 'flexibleengine',
    displayNameFull: 'Flexible Engine',
    logo: flexibleengine.logo,
    providerCanonical: 'flexibleengine',
  },
  openstack: {
    displayName: 'OpenStack',
    displayNameFull: 'OpenStack',
    logo: openstack.logo,
    providerCanonical: 'openstack',
  },
}

export const TRIMMED_SERIES_MAX_ROW_COUNT = 5

export const groupingOptions = [
  {
    label: i18n.t('cloudCostManagement.groupBy.noGrouping'),
    value: null,
  },
  {
    label: i18n.t('cloudCostManagement.groupBy.project'),
    value: 'project',
  },
  {
    label: i18n.t('cloudCostManagement.groupBy.service'),
    value: 'service',
  },
  {
    label: i18n.t('cloudCostManagement.groupBy.environment'),
    value: 'environment',
  },
  {
    label: i18n.t('cloudCostManagement.groupBy.location'),
    value: 'location',
  },
  {
    label: i18n.t('cloudCostManagement.groupBy.resourceType'),
    value: 'instance_type',
  },
  {
    label: i18n.t('cloudCostManagement.groupBy.linkedAccount'),
    value: 'linked_account',
  },
  {
    label: i18n.t('cloudCostManagement.groupBy.category'),
    value: 'category',
  },
]

const buildFilterQueryString = (queryBody) => {
  const NON_LIST_PARAMS = ['begin', 'end', 'currency', 'granularity', 'resource_tagging']
  // adding NON_LIST_PARAMS key/values as default, as they need to persist through filtering
  // requests and not depend from user input
  const searchParams = new URLSearchParams(_.pick(queryBody, NON_LIST_PARAMS))
  // we cycle through all filter entries not in NON_LIST_PARAMS...
  _.toPairs(_.omit(queryBody, NON_LIST_PARAMS)).forEach(([key, values]) => {
    // ...pushing non-nullish filter key/values into searchParams
    values.filter(_.isString).forEach((value) => searchParams.append(key, value))
  })
  // and finally returning a well formatted URL query string
  return `?${searchParams.toString()}`
}

const formatPercentage = (percentage) => {
  if (percentage > 99.99 && percentage < 100) return '>99.99%'
  if (percentage > 0 && percentage < 0.01) return '<0.01%'
  return `${percentage.toFixed(2)}%`
}

const formatDate = (date) => {
  return moment(date).format('MMM D, YYYY') // eslint-disable-line you-dont-need-momentjs/format
}

const formatTooltip = (params, mainGroupingParam, type, currency, totals = []) => {
  const [{ data: [date] }] = params
  const hasMoreThanOneItem = params.length > 1
  const total = _.find(totals, ({ date: totalDate }) => formatDate(totalDate) === date)
  const fallbackLabel = getDisplayName(undefined, mainGroupingParam)
  const amountFormatters = {
    cost: (amount) => formatAmount(amount, { currency }),
    co2e: (amount) => `${formatAmount(amount)} KgCO2e`,
    kwh: (amount) => `${formatAmount(amount)} kWh`,
  }
  const totalEntry = total && hasMoreThanOneItem
    ? `<div class='d-flex justify-space-between'>
      <span>${i18n.t('untranslated.total')}:</span>
      <strong style='text-align: left;'>${amountFormatters[type](total[type])}</strong>
    </div>`
    : ''
  const seriesItems = params.map(({ seriesName, marker, data }) => `
    <div class='d-flex justify-space-between'>
      <span>${hasMoreThanOneItem ? `${marker}&nbsp;` : ''}${!['undefined', ''].includes(seriesName) ? seriesName : fallbackLabel}:&nbsp;</span>
      <strong style='text-align: left;'>${amountFormatters[type](data[1])}</strong>
    </div>`).join('')
  return `<p class='mb-1'>${date}</p>${totalEntry}${seriesItems}`
}

const getAccountStatus = (enabled, status) => {
  if (!enabled) return 'disabled'
  if (_.startsWith(status, 'import')) return 'importing'
  if (_.startsWith(status, 'error')) return 'errored'
  return 'enabled'
}

const getCredentialType = (providerCanonical) => {
  switch (providerCanonical) {
    case 'aws': return 'aws'
    case 'azurerm': return 'azure_storage'
    case 'google': return 'gcp'
    default: return ''
  }
}

const getItemTrend = ({ values, first_cost: firstCost, last_cost: lastCost }) => {
  const first = _.round(firstCost ?? _.head(values)?.cost, 2)
  const last = _.round(lastCost ?? _.last(values)?.cost, 2)
  if (!first) {
    if (!last) return 0
    return last < 0 ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY
  }
  return (last - first) / first * 100
}

const getItemProvidersAlphabetically = ({ providers }) => {
  return _.map(providers, 'canonical').sort().join('')
}

const getTrimmedSeries = (series, type) => {
  if (_.size(series) <= TRIMMED_SERIES_MAX_ROW_COUNT) return series

  const rowTotals = _.mapValues(series, (items) => _.sumBy(items, type))
  const seriesArray = _.toPairs(series)

  const sortedRows = _.orderBy(_.toPairs(rowTotals), ([_, value]) => value, ['desc'])
  const sortedCanonicals = sortedRows.map(([canonical]) => canonical)
  const topRowCanonicals = sortedCanonicals.slice(0, TRIMMED_SERIES_MAX_ROW_COUNT - 1)

  const [topRows, otherRows] = _.partition(seriesArray, ([canonical]) => topRowCanonicals.includes(canonical))
  const othersValues = otherRows.reduce((acc, [_, data]) => {
    if (!acc.length) return data
    return acc.map((value, idx) => ({
      date: value.date,
      cost: value.cost + data[idx].cost,
      co2e: value.co2e + data[idx].co2e,
      kwh: value.kwh + data[idx].kwh,
    }))
  }, [])

  return { ..._.fromPairs(topRows), [i18n.t('others')]: othersValues }
}

const graphColors = ['#1C9797', '#B381F7', '#EB53B0', '#6A8EF3', '#F4B740']

const getDisplayName = (canonical, mainGroupingParam) => {
  if (canonical && canonical !== 'undefined') return canonical
  return mainGroupingParam
    ? i18n.t('cloudCostManagement.noGroupingCriteria', { criteria: i18n.t(`cloudCostManagement.groupBy.${mainGroupingParam}`) })
    : i18n.t('cloudCostManagement.totalCost')
}

const hasCloudCostManagementEB = (externalBackends) => {
  return _.some(externalBackends, ['purpose', 'cost_explorer'])
}

const parseHistogramDates = ({ begin, end, granularity }) => {
  const format = 'yyyy-MM-dd'
  let now
  switch (granularity) {
    case 'month':
      now = $date.startOfMonth($date.parse(begin, format, new Date()), { weekStartsOn: 1 })
      break
    case 'week':
      now = $date.startOfWeek($date.parse(begin, format, new Date()), { weekStartsOn: 1 })
      break
    default:
      now = $date.parse(begin, format, new Date())
      break
  }
  const dates = []

  while (!$date.isAfter(now, $date.parse(end, format, new Date()))) {
    dates.push($date.format(now, format))
    now = $date.add(now, { [`${granularity}s`]: 1 })
  }

  return dates.map((date) => $date.parse(date, format, new Date()).getTime())
}

const sortItems = (items, [sortBy], [sortDesc], type = 'cost') => {
  let sortFn = sortBy
  if (['previous_total', 'first_cost'].includes(sortBy)) sortFn = getItemTrend
  else if (sortBy === 'providers') sortFn = getItemProvidersAlphabetically
  else if (sortBy === 'current_total') sortFn = ({ values }) => _.sumBy(values, type)
  const order = sortDesc ? 'desc' : 'asc'
  return _.orderBy(items, sortFn, order)
}

const getTotalsFromAggregateData = (aggregateData) => {
  const { cost, providerDatas } = aggregateData
  const oldestCost = _.sumBy(providerDatas, ({ costData }) => _.head(costData)?.cost || 0)
  const latestCost = _.sumBy(providerDatas, ({ costData }) => _.last(costData)?.cost || 0)
  const difference = latestCost - oldestCost

  return {
    cost,
    latestCost,
    oldestCost,
    difference,
  }
}

export {
  formatPercentage,
  buildFilterQueryString,
  formatDate,
  formatTooltip,
  formatAmount,
  getAccountStatus,
  getCredentialType,
  getDisplayName,
  getItemTrend,
  getItemProvidersAlphabetically,
  getTotalsFromAggregateData,
  getTrimmedSeries,
  graphColors,
  hasCloudCostManagementEB,
  parseHistogramDates,
  sortItems,
}
