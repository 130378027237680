import { throwBetterError } from '@/utils/helpers'

const components = {
  CyAppLogo: () => import('@/components/app-logo.vue'),
  CyAppPublicLogo: () => import('@/components/app-public-logo.vue'),
  CyAvatar: () => import('@/components/avatar.vue'),
  CyBetaTag: () => import('@/components/beta-tag.vue'),
  CyButton: () => import('@/components/button.vue'),
  CyDeleteButton: () => import('@/components/delete-button.vue'),
  CyDeprecatedTag: () => import('@/components/deprecated-tag.vue'),
  CyDevBtn: () => import('@/components/dev/btn.vue'),
  CyDevDump: () => import('@/components/dev/dump.vue'),
  CyEditButton: () => import('@/components/edit-button.vue'),
  CyFormsAssignOwner: () => import('@/components/forms/assign-owner.vue'),
  CyIconCredential: () => import('@/components/icon-credential.vue'),
  CyMember: () => import('@/components/member.vue'),
  CyMenu: () => import('@/components/menu.vue'),
  CyModal: () => import('@/components/modal.vue'),
  CyNotification: () => import('@/components/notification.vue'),
  CyTag: () => import('@/components/tag.vue'),
  CyTooltip: () => import('@/components/tooltip.vue'),
}

function install (Vue) {
  for (const [componentName, componentPromise] of _.entries(components)) {
    try {
      Vue.component(componentName, componentPromise)
    } catch (error) {
      throwBetterError(new Error(error), install)
    }
  }
}
export default { install }
