import { InternalError, AppError, types as errTypes } from '../config/errors'
import { getOwnerUsername, hasNoOwner } from '@/utils/helpers'

// ! needed in order for storybook to access process.env (buggy AF)
const isStoryBookRun = !!process.env.VUE_CLI_STORYBOOK
if (isStoryBookRun) console.info(process.env)

const { VUE_APP_API_URL, VUE_APP_API_VERSION } = process.env

/**
 * Send Request(s) and handle responses according Cycloid conventions and using
 * the resources used by Cycloid.io Web App
 *
 * send operates as follow:
 * Send a HTTP requests and always resolve the promise despite of an error,
 * because the error is handled commonly across the application, the caller
 * must not do anything with the error.
 * However the caller may want to execute some specific logic (e.g. clear the
 * user credentials) if the request hasn't been fulfilled, so the resolved
 * promise contains a Response object or null when there has been an error.
 *
 * @typedef {function} send
 * @param {Request} req - The request to send
 * @param {number[]} acceptedStatuses - The list of HTTP response status codes
 *            which the caller wants to handle; All 2XX codes are considered
 *            successful so there is not need to add those to this list.
 * @returns {Promise} - It always resolve the promise if the `fetch` function
 *            resolves the promise, that means that the promise is always resolved
 *            despite of the server response, but it's resolved in the following
 *            manner:
 *            - If the response is OK, it returns the original Response object
 *            - If the response is no OK (no 2XX and nor present in the
 *              acceptedStatuses) then return null; then this happen the client
 *              should do anything with the error because it's handled by the
 *              application mechanism using our internal Errors and the store,
 *              however the caller will easily know that cannot do anything
 *              with the response because it's NULL and do some generic thing
 *              on top of the global error handling application logic, but with
 *              no information of the error at all, for example showing a temporary
 *              bubble about the error if the general one isn't enough.
 */

/**
 * InternalHelpers is a class to pin the implementations of the native interfaces
 * used by the internal functions and keep each HTTP client instance isolated
 * after its creation.
 * @class
 *
 * The object of this class doesn't have any state rather than mentioned interfaces
 * implementations.
 */
class InternalHelpers {
  constructor (fetch, Headers, Request, URLSearchParams) {
    this.fetch = fetch
    this.Headers = Headers
    this.Request = Request
    this.URLSearchParams = URLSearchParams
  }

  /**
   * Create and object with 2 methods for creating a Request instance for a
   * public and authorized API requests.
   *
   * @param {Object} baseInit - Base Request init object for all the requests
   * @param {Object} store - Vuex store instance
   * @param {string} baseURL - the API base URL
   * @returns {Object} - Return an object with the 2 mentioned methods
   * @throws SyntaxError if the body cannot be JSON stringified
   */
  createRequestCtors (baseInit, store, baseURL) {
    // Content-Header cannot be set in headers with the guard set to "request"
    // which is obviously set to the headers obtained from a Request object,
    // hence we don't need to append such header because it's discarded.
    // @see [Using Fetch Guard]{@link https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#Guard}
    function request (init, path, { body, query }) {
      const headers = new self.Headers()
      let jsonBody

      if (body) {
        jsonBody = JSON.stringify(body)
      }

      return new self.Request(
        self.composeURL(baseURL, path, query),
        self.extendsInit(init, { body: jsonBody, headers }),
      )
    }

    const self = this
    return {
      createPublicReq (method, path, bodyAndQuery) {
        if (!bodyAndQuery) {
          bodyAndQuery = {}
        }

        const init = self.extendsInit(baseInit, { method })
        return request(init, path, bodyAndQuery)
      },
      createAuthReq (method, path, bodyAndQuery) {
        if (!bodyAndQuery) {
          bodyAndQuery = {}
        }

        const init = self.extendsInit(baseInit, {
          method,
          headers: {
            Authorization: `Bearer ${store.state.auth.jwt}`,
          },
        })
        return request(init, path, bodyAndQuery)
      },
    }
  }

  /**
   * Create an URL formed by the base URL, path and query parameters
   *
   * @param {string} baseURL - The baseURL without ending with '/'
   * @param {string} path - The URL path to request starting with '/' or without
   * @param {Object} [query] - An object which represents the query parameters to
   *              request
   * @returns {string} - The full URL composed by the 3 provided parameters
   */
  composeURL (baseURL, path, query = null) {
    if (path[0] !== '/') {
      path = `/${path}`
    }

    let params = ''
    if (query) {
      params = `?${(new this.URLSearchParams(query)).toString()}`
    }

    return `${baseURL}${path}${params}`
  }

  /**
   * Create a new init object from the base and the additions. The properties of
   * additions overrides the base ones except the headers, which are merged.
   *
   * @see [init object Request parameter] {@link https://developer.mozilla.org/en-US/docs/Web/API/Request/Request#Parameters}
   * @param {Object} base - Base Request init object
   * @param {Object} additions - Additions Request init object
   * @returns {Object} - New Request init object
   */
  extendsInit (base, additions) {
    const headers = new this.Headers()
    const dst = Object.assign({}, base, additions)

    // Merge headers
    for (const sh of [base.headers, additions.headers]) {
      if (!sh) {
        continue
      }

      const entries = (sh.entries) ? sh.entries() : Object.entries(sh)
      for (const [k, v] of entries) {
        headers.append(k, v)
      }
    }

    dst.headers = headers
    return dst
  }

  /**
   * Create a function to send requests and handle the common logic for send and
   * API error responses.
   *
   * @param {Object} store - Vuex store instance
   * @param {Object} i18n - Instance of vue-i18n
   * @returns {send} - The send function which use the provided, by parameters,
   *              resource instances
   */
  sendCtor (store, i18n) {
    const fetch = this.fetch
    return async function send (req, acceptedStatuses = []) {
      // Clone the request in case that the response won't be accepted and the
      // request body must be read after calling fetch which read the body of
      // the request.
      const reqBackup = req.clone()
      let reqBackupBody
      try {
        const res = await fetch(req)
        if (!res.ok) {
          if (acceptedStatuses.findIndex((s) => s === res.status) === -1) {
            // If it's >=500 it's an API error, below that, it should be an
            // app error because it's not handling the status code for some
            // reason
            const type = (res.status >= 500) ? errTypes.API : errTypes.APP
            // Getting the body with text() method is a safe way for not getting
            // JSON parsing errors if the body isn't JSON format and we don't need
            // a JS Object for metadata purpose
            let resBody
            [reqBackupBody, resBody] = await Promise.all([reqBackup.text(), res.text()])
            throw new InternalError(
              'youdeploy-api-http-client.send: Unexpected API response status code',
              type,
              {
                httpRequest: {
                  method: req.method,
                  url: req.url,
                  body: reqBackupBody,
                },
                httpResponse: {
                  status: res.status,
                  body: resBody,
                },
              },
            )
          }
        }

        return res
      } catch (err) {
        // This is an unrecoverable error, because it isn't expected
        let error
        if (err instanceof InternalError) {
          error = AppError.fromInternalError(err, i18n.t('errors.system'))
        } else {
          const metadata = {
            httpRequest: {
              method: req.method,
              url: req.url,
            },
          }

          if (!reqBackupBody) {
            try {
              reqBackupBody = await reqBackup.text()
              metadata.httpRequest.body = reqBackupBody
            } catch (err) {
              metadata.httpRequest.reason_no_body = err
            }
          } else {
            metadata.httpRequest.body = reqBackupBody
          }

          error = AppError.fromError(
            err,
            'youdeploy-api-http-client.send: FetchAPI promise rejection',
            i18n.t('errors.network'),
            errTypes.SYS,
            metadata,
          )
        }

        // Hack to get a meaningful error from a request that returned 4xx or 5xx AND has a valid json body
        try {
          const resBody = _.get(err, 'metadata.httpResponse.body')
          const resError = JSON.parse(resBody)

          if (_.has(resError, 'errors')) error = resError.errors
        } catch (err) {}

        const hasNoAppErrorYet = !_.some(store.state.alerts.error, ({ code }) => code === 'AppError')
        if (hasNoAppErrorYet) await store.dispatch('alerts/SHOW_ALERT', { type: 'error', content: _.isArray(error) ? error : [error] }, { root: true })

        return null
      }
    }
  }
}

/**
 * HTTP client expose 2 methods to perform public requests and authorized
 * responses, using the baseURL and API version provided to its constructor
 * function
 *
 * @typedef {Object} httpClient
 * @method publicRequest - Sends a request to the path without adding the authorization
 *              header
 * @method authRequest - Sends a request to the path adding the authorization
 *              header using the token present in the store.state.auth.jwt
 *
 * Both methods have the following parameters
 * @param {string} method - The HTTP method to use
 * @param {string} path - The path to request; It's append to the base URL
 * @param {Object} [queryAndBody]: Contains the query and/or body
 * @param {Object} [queryAndBody.query]: Plain object which represents the query
 *              parameters to send in the request
 * @param {Object} [queryAndBody.body]: Plain object which represents the body
 *              to send in the request
 * @param {number[]} [acceptedStatuses]: The list of status codes to accept as
 *              valid in order to return the response to avoid handling it as
 *              an common error. All the 2XX codes are always successful, so
 *              you don't have to add them, all the >299 status codes are treated
 *              as errors, so if the API endpoint that you're requesting can return
 *              an error which must be handled by the caller (e.g. 422 in a form
 *              component) you must added in the list.
 * @returns {Promise} - @see [send type]{@link send} defined in this file because
 *              these methods call it underneath. Send doc contains the explanation
 *              of the promise returned here
 */

/**
 * Create an object which has 2 methods to send requests to YD API.
 * One method is for sending requests without authentication and another to
 * send requests with it.
 *
 * @param {Object} store - Vuex store instance
 * @param {Object} i18n - Instance of vue-i18n
 * @param {WebAPI~Fetch} [fetch] - The implementation of the Web API fetch
 *              function to use
 *              @see [Fetch]{@link https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API}
 * @param {WebAPI~HeadersInterface} [Headers] - The implementation of the Web
 *              API Headers interface to use
 *              @see [Header]{@link https://developer.mozilla.org/en-US/docs/Web/API/Headers}
 * @param {WebAPI~RequestInterface} [Request] - The implementation of the Web
 *              API Request interface to use
 *              @see [Request]{@link https://developer.mozilla.org/en-US/docs/Web/API/Request}
 * @param {WebAPI~URLSearchParams} [URLSearchParams] - The implementation of the
 *              Web API URLSearchParams interface to use
 *              @see [URLSearchParams]{@link https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams}
 *
 * @returns {httpClient} - a new httpClient instance.
 *              An httpClient instance has 2 methods:
 *              * publicRequest: Which makes a request without sending the Authorization header
 *              * authRequest: Which makes a request sending the Authorization header and the
 *                             credential values present in the store
 *
 *              Both methods have the same parameters and they are the following:
 *              @param {string} method - The HTTP verb (e.g. GET, POST, ...)
 *              @param {string} path - The URL path to append to the baseURL for the request.
 *              @param {Object} bodyAndQuery - Object with 2 properties
 *              @param {Object} bodyAndQuery.body - The body to send with the request, obviously sending a body with
 *                    and HTTP verb which doesn't accept body may return an error or will be discarded, depending of
 *                    underlying WebAPI~Fetch implementation provided.
 *              @param {Object|Array} bodyAndQuery.query -  The query parameters of the request to send.
 *                    When it's an object, the property names are the name of the query parameters and property values
 *                    the associated query parameter values.
 *                    When it's an array, it must be an array of pairs; a pair is an array of just 2 items. An array
 *                    could be always use, but we advice to only use for one specific case that an object cannot
 *                    represent which is when it's needed to send the same query parameter name with different values
 *                    (e.g. ?env=prod&env=dev, then you are force to use an array in this format
 *                    [['env', 'prod'], ['env', 'dev']]).
 *                    These logic is the same that applied by the draft of the
 *                    [WebAPI~URLSearchParams]{@link https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams}
 *                    and it should be respected by the implementation passed to this constructor function.
 *              @param {number[]} acceptedStatuses - The list HTTP response status codes (<200 and >299) which are not
 *                    considered and error so the response will be returned rather than an error.
 *                    This is useful, because some statuses codes may be needed to be handled by an special logic
 *                    meanwhile the other must be considered errors and handle them commonly.
 *             @return {Promise} - Resolve with the response object returned by WebAPI~Fetch implementation when there
 *                    is a successful response (>=200 and <299) (which is defined by the 'ok' property that the
 *                    [Response]{@link https://developer.mozilla.org/en-US/docs/Web/API/Response#Properties}
 *                    object that WebAPI~Fetch implementation should return) or its status code is in the
 *                    acceptedStatuses codes array, otherwise the promise is resolved with `null` and the error (with
 *                    proper metadata) is reported to the event hub errors channel.
 *                    NOTE that the promise is never rejected, however it doesn't mean the errors are dropped, they
 *                    are reported to the store for being handled by the common application error handling, as
 *                    commented above, use acceptedStatuses  array to handle specific exceptions.
 *                    @see [alerts]{@link store/modules/alerts.js} and @see [errors]{@link utils/errors.js} for more
 *                    and deeper information of the implementation details.
 */
export default function createHTTPClient (
  store,
  i18n,
  baseURL = VUE_APP_API_URL,
  apiVersion = VUE_APP_API_VERSION,
  fetch = window.fetch,
  Headers = window.Headers,
  Request = window.Request,
  URLSearchParams = window.URLSearchParams,
) {
  const baseInit = {
    mode: 'cors',
    credentials: 'omit',
    cache: 'no-store',
    redirect: 'manual',
    referrer: 'client',
    headers: {
      'Content-Type': `application/vnd.cycloid.io.v${apiVersion}+json`,
    },
  }

  const lastIdx = baseURL.length - 1
  if (baseURL[lastIdx] === '/') baseURL = baseURL.slice(0, lastIdx)

  const helpers = new InternalHelpers(fetch, Headers, Request, URLSearchParams)
  const { createPublicReq, createAuthReq } = helpers.createRequestCtors(baseInit, store, baseURL)
  const send = helpers.sendCtor(store, i18n)

  return {
    async publicRequest (method, path, bodyAndQuery = {}, acceptedStatuses = []) {
      return send(createPublicReq(method, path, bodyAndQuery), acceptedStatuses)
    },
    async authRequest (method, path, bodyAndQuery = {}, acceptedStatuses = []) {
      return send(createAuthReq(method, path, bodyAndQuery), acceptedStatuses)
    },
  }
}

/**
 * The function receives an object in order to create a list of pairs with it.
 * The list of pairs presents a key and a stringified value when the value of the
 * property object isn't an array; when the value is an array, one pair for each
 * array item will be add to the returned list of pairs having the object
 * property name as key, so there will be pairs witch the same key.
 *
 * For clarification a pair is an array of 2 values, in this context, the first
 * element of the array is named 'key' and the second 'value'
 *
 * Example:
 *  obj = { id: 10, label: ['project', 'client'], owner: { name: 'Cycloid' } }
 *  result = [['id', '10'], ['label', 'project'], ['label', 'client'], ['owner', '[object Object]']]
 *
 *  As you can see in the example the plain object assigned to the 'owner' property
 *  becomes such value because the toString method of Object is used to obtain
 *  it's stringified format.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/URLSearchParams URLSearchParams}
 *
 * @param {Object} obj - The object to convert to an array of pairs
 * @returns {Array} - The array of pairs.
 */
export function objectToListOfPairs (obj) {
  if (!obj || (typeof obj !== 'object')) {
    throw new Error('the parameter must be an object')
  }

  const list = []
  for (const [p, v] of Object.entries(obj)) {
    if (Array.isArray(v)) {
      for (const i of v) {
        list.push([p, i.toString()])
      }
      continue
    }

    list.push([p, v.toString()])
  }

  return list
}

/** Converts the .owner property to a `String` (username) or `null` (no owner assigned)
 *
 * Necessary for sending PUT and POST requests for entities with `.owner`.
 *
 * @param {Object}              item
 * @param {Object}              [options={}]
 * @param {Vuex.Store?}         [options.store=]
 * @param {boolean?}            [options.isCreation=false]
 *
 * @returns {username|null}
 */
export async function convertOwnerToUsername (item, { store, isCreation = false } = {}) {
  const { default: importedStore } = store || await import('@/store')
  const { getters } = store || importedStore

  if (_.isEmpty(getters)) {
    console.error('[convertOwnerToUsername], Store.Getters are empty. Please fix importedStore or pass store as a param.')
    return item
  }

  const { isUsingMSP, username, orgMembers } = getters
  const owner = _.find(orgMembers, ['username', getOwnerUsername(item?.owner ?? (isCreation ? username : null))])?.username ?? null

  _.some([isUsingMSP && isCreation, hasNoOwner(owner)])
    ? Object.assign(item, { owner: null })
    : Object.assign(item, { owner })

  if (!_.isNull(item.owner) && !_.isString(item.owner)) {
    console.error('Owner being sent to api is neither null nor a username. Please fix it.', { owner: item.owner })
  }

  return item
}
