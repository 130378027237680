<template>
  <v-app
    v-show="appearance"
    :class="['cy-app', {
      'cy-app--full-screen': isFullScreen,
      'cy-app--public-status': isPublicStatusPage,
    }]"
    :style="[{
      '--appearance-bg-color': _.get(backgroundColor, 'cssString'),
      '--appearance-text-color': _.get(textColor, 'cssString'),
      '--appearance-text-color-values': _.get(textColor, 'cssValues'),
    }]"
    :aria-locale="$i18n.locale">
    <component
      v-if="isRouteReady"
      :variant="variant"
      :fit-on-screen="fitOnScreen"
      :is="layout">
      <router-view/>
    </component>
  </v-app>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'CyApp',
  components: {
    CyAppLayout: () => import('@/layouts/app-layout'),
    CyDefaultLayout: () => import('@/layouts/default-layout'),
    CyPublicLayout: () => import('@/layouts/public-layout'),
  },
  computed: {
    ...mapState({
      isFullScreen: (state) => state.layout.isFullScreen,
    }),
    ...mapGetters([
      'appearance',
    ]),
    isRouteReady () {
      return !!this.$route.name
    },
    layout () {
      const fileName = _.chain(`${_.get(this.$route, 'meta.layout.name', 'default')}-layout`)
        .camelCase()
        .upperFirst()
        .value()
      return `Cy${fileName}`
    },
    variant () {
      return _.get(this.$route, 'meta.layout.variant')
    },
    fitOnScreen () {
      return _.get(this.$route, 'meta.layout.fitOnScreen', false)
    },
    // eslint-disable-next-line vue/no-unused-properties
    _vuetify () {
      return this.$isStagingOrDev ? this.$vuetify : null
    },
    isPublicStatusPage () {
      return this.$route.name === 'status' && !this.isAuthenticated
    },
    backgroundColor () {
      return this.appearance?.color
    },
    textColor () {
      return this.backgroundColor?.contrastingColor ?? null
    },
  },
  watch: {
    'appearance.favicon' (newVal) {
      if (newVal) this.setFavicon()
    },
  },
  async created () {
    window.addEventListener('beforeunload', () => sessionStorage.setItem('isRefreshing', true))
    await this.fetchAppearance()
    this.setFavicon()
    this.checkAppVersion()
  },
  methods: {
    ...mapActions([
      'FETCH_APP_APPEARANCE',
    ]),
    async fetchAppearance () {
      const orgCanonical = this.$route.params.orgCanonical ?? this.orgCanonical
      await this.FETCH_APP_APPEARANCE(orgCanonical)
    },
    setFavicon () {
      const favicon = this.appearance?.favicon
      const currentEnv = _.findKey({
        dev: this.$isDevMode,
        ephemeral: this.$isEEnv,
        staging: this.$isStaging,
        beta: this.$isBeta,
      })

      if (!currentEnv && !favicon) return

      const DOMfavicons = document.querySelectorAll('[data-js="favicon"]')
      DOMfavicons.forEach((DOMfavicon) => {
        const isNewFaviconCycloidDefault = /console.cycloid/.test(favicon)
        const isOldFaviconCycloidDefault = /console.cycloid\/static/.test(DOMfavicon?.href)
        DOMfavicon.href = _.every([isNewFaviconCycloidDefault, isOldFaviconCycloidDefault])
          ? DOMfavicon.href.replace('/prod', `/${currentEnv}`)
          : favicon
      })
    },
    checkAppVersion () {
      const { orgCanonical } = this
      const { VUE_APP_API_URL, NODE_ENV, VUE_APP_VERSION } = process.env
      const currentEnvironment = `[env: ${NODE_ENV}]---[api: ${VUE_APP_API_URL}]`
      const hasEnvChanged = VUE_APP_API_URL && !_.isEqual(currentEnvironment, localStorage.getItem(LSK.ENVIRONMENT))

      if (_.isEmpty(localStorage.getItem(LSK.APP_VERSION))) localStorage.setItem(LSK.APP_VERSION, VUE_APP_VERSION)
      const hasNewVersion = !_.isEqual(VUE_APP_VERSION, localStorage.getItem(LSK.APP_VERSION))

      const clearStorageButKeep = (storage, keysToKeep) => {
        const itemsToKeep = {}
        keysToKeep.forEach((key) => {
          itemsToKeep[key] = storage.getItem(key)
        })
        storage.clear()
        keysToKeep.forEach((key) => {
          storage.setItem(key, itemsToKeep[key])
        })
      }

      // Keys to keep in localStorage and sessionStorage
      const keysToKeep = [LSK.APP_VERSION]

      // Clear specific keys from localStorage and sessionStorage
      if ((hasNewVersion || hasEnvChanged) && window.location.pathname !== '/oidc-popup-callback') {
        clearStorageButKeep(sessionStorage, keysToKeep)
        clearStorageButKeep(localStorage, keysToKeep)
      }

      // Clear local and session storage to avoid problems between switching between staging/local/beta etc.
      if (hasEnvChanged && NODE_ENV !== 'test') {
        console.info(`[Cycloid] Local storage has been reset: switched from ${localStorage.getItem(LSK.ENVIRONMENT)} to ${currentEnvironment}`)
        localStorage.setItem(LSK.ENVIRONMENT, currentEnvironment)
      }

      // Force logout when app version increases
      if (hasNewVersion) this.$router.push({ name: 'logout', params: { orgCanonical } })?.catch(() => { /* silenced */ })
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-app {
  position: relative;
  background: var(--appearance-bg-color);
  color: get-color("primary");

  ::v-deep .v-application--wrap {
    z-index: 0;
    flex-direction: row;
  }

  &--full-screen {
    ::v-deep {
      .cy-sidebar,
      .cy-toolbar {
        display: none;
      }
    }
  }

  &--public-status {
    background-color: get-color("white");
  }
}

.v-menu__content.menuable__content__active {
  border-top: none;
  border-radius: 10px 0 10px 10px;
  box-shadow:
    0 5px 5px -3px get-color("black", $alpha: 0.2),
    0 8px 10px 1px get-color("black", $alpha: 0.15),
    0 10px 13px 4px get-color("black", $alpha: 0.15);
}
</style>
