import Vue from 'vue'
import i18n from '@/utils/plugins/i18n'
import { vuexMixin } from '@/utils/helpers'

export const initialState = {
  detail: {
    name: '',
    description: '',
    owner: null,
    last_used: null,
    token: null,
    canonical: null,
    rules: [],
  },
  errors: [],
}
const STATE = _.cloneDeep(initialState)

const {
  mutations: { SET_ERRORS, CLEAR_ERRORS, RESET_STATE },
  actions: { GET_OWNER_OBJECT },
} = vuexMixin(initialState)

const GETTERS = {}

export const actions = {
  GET_OWNER_OBJECT,

  async CREATE_API_KEY ({ commit, dispatch, rootGetters: { orgCanonical } }, { apiKey }) {
    commit('CLEAR_API_KEY_ERRORS')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.createAPIKey(orgCanonical, apiKey) || {}
    if (data) {
      commit('SET_API_KEY', data)
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.apiKey.created') }, { root: true })
    }
    if (errors) commit('SET_ERRORS', { errors })
  },

  async DELETE_API_KEY ({ commit, dispatch, rootGetters: { orgCanonical } }, { apiKey, $router }) {
    commit('CLEAR_API_KEY_ERRORS')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteAPIKey(orgCanonical, apiKey.canonical) || {}
    if (errors) commit('SET_ERRORS', { errors })
    else {
      if ($router) $router.push({ name: 'apiKeys' })
      commit('RESET_API_KEY_STATE')
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.apiKey.deleted', { apiKeyName: apiKey.name }) }, { root: true })
    }
  },

  async GET_API_KEY ({ commit, rootGetters: { orgCanonical } }, { apiKeyCanonical }) {
    commit('CLEAR_API_KEY_ERRORS')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getAPIKey(orgCanonical, apiKeyCanonical) || {}
    if (data) commit('SET_API_KEY', data)
    if (errors) commit('SET_ERRORS', { errors })
  },

  async UPDATE_API_KEY ({ commit, dispatch, rootGetters: { orgCanonical } }, { apiKey, successMessage }) {
    commit('CLEAR_API_KEY_ERRORS')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.updateAPIKey(orgCanonical, apiKey) || {}
    if (data) {
      commit('SET_API_KEY', await dispatch('GET_OWNER_OBJECT', { data, oldVal: apiKey }))
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: successMessage || i18n.t('alerts.success.apiKey.updated', { apiKeyName: data.name }) }, { root: true })
    }
    if (errors) commit('SET_ERRORS', { errors })
  },
}

export const mutations = {
  CLEAR_API_KEY_ERRORS: CLEAR_ERRORS,
  RESET_API_KEY_STATE: RESET_STATE,
  SET_ERRORS,

  SET_API_KEY (state, apiKey) {
    Vue.set(state, 'detail', apiKey)
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
