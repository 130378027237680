import createPermissionChecker, { mapActionNameToKeyPerAction } from '@/utils/plugins/permissions'
import createYDAPI from '@/utils/api'

/**
 * Create a mock of a cycloid~permissions instance.
 * @returns {cycloid~permissions} - A mock instance
 */
export function createPermissionCheckerMock () {
  // Create a permissions instance in order to know the methods to replace by stubs.
  // The store instance isn't needed because it's only used once the methods are
  // executed and not by the creator function, so we don't provide any
  const permissions = createPermissionChecker()

  for (const action in permissions) {
    if (action === 'actions') permissions[action] = mapActionNameToKeyPerAction()
    permissions[action] = jest.fn()
  }
  permissions.getRouteRequiredActions = jest.fn().mockReturnValue([])

  return permissions
}

/**
 * Create a mock of a cycloid~ydAPI instance.
 * @returns {cycloid~ydAPI} - A mock instance
 */
export function createYDAPIMock () {
  // Create a YD API instance in order to know the methods to replace by stubs.
  // Not All the dependencies passed to the constructor aren't used in the
  // constructor, hence we only provide the ones needed
  const ydAPI = createYDAPI({})

  for (const endpoint in ydAPI) ydAPI[endpoint] = jest.fn().mockResolvedValue()

  return ydAPI
}
