import ECharts from 'vue-echarts'

import { use, registerTheme } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart, LineChart, BarChart } from 'echarts/charts'
import {
  GridComponent,
  LegendComponent,
  LegendScrollComponent,
  TooltipComponent,
  MarkLineComponent,
  MarkAreaComponent,
  MarkPointComponent,
  DataZoomComponent,
} from 'echarts/components'

import theme from '@/vendor/echarts-theme.json'

registerTheme('cycloid', theme)
use([
  CanvasRenderer,
  PieChart,
  LineChart,
  BarChart,
  GridComponent,
  LegendComponent,
  LegendScrollComponent,
  TooltipComponent,
  MarkLineComponent,
  MarkAreaComponent,
  MarkPointComponent,
  DataZoomComponent,
])

export default {
  install (Vue) {
    Vue.component('ECharts', ECharts)
  },
}
