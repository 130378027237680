import VueGtm from '@gtm-support/vue2-gtm'
import router from '@/router'

export default {
  name: VueGtm,
  options: {
    id: process.env.VUE_APP_GTM_ID || 'GTM-DUMMYID', // The plugins requires to be passed a well formatted ID (even when disabled)
    // The following 'enabled' attribute allows us to still load the plugin but
    // disable sending any events/pageviews when VUE_APP_GTM_ID is empty.
    // This way we don't need to have any conditional statements in the code and
    // we can assume that the plugin is always present.
    enabled: !_.isEmpty(process.env.VUE_APP_GTM_ID),
    debug: false, // Outputs event/pageview info in the console during development.
    vueRouter: router, // Passing the router enables automatic pageview tracking.
  },
}
