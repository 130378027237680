/* eslint-disable frabbit-vue/vue-no-unused-vuex-properties */
import { mapGetters } from 'vuex'

export default {
  install (Vue) {
    Vue.mixin({
      computed: {
        ...mapGetters([
          'isAuthenticated',
          'isOrgAdmin',
          'isEntityOwner',
          'isUsingMSP',
          'orgCanonical',
          'orgName',
          'projectName',
          'username',
        ]),
        ...mapGetters('organization', [
          'organization',
        ]),
        ...mapGetters('dev', [
          '$isBetaFeature',
          '$showDevThings',
          '$showFeature',
        ]),
      },
    })
  },
}
