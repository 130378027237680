// for more aws logos: https://worldvectorlogo.com/search/aws
import awsLogo from './aws/logo.svg'
import awsApiGateway from './aws/apigateway.svg'
import awsCloudFront from './aws/cloudfront.svg'
import awsDynamodb from './aws/dynamodb.svg'
import awsEc2 from './aws/ec2.svg'
import awsLambda from './aws/lambda.svg'
import awsS3 from './aws/s3.svg'

import vaultLogo from './vault/logo.svg'
import vaultLogoColor from './vault/logo-color.svg'
import vaultLogoWithText from './vault/logo-with-text.png'

import ansibleLogo from './ansible/logo.svg'

import terraformLogo from './terraform/logo.svg'

import googleLogo from './google/logo.svg'

import kubernetesLogo from './kubernetes/logo.png'
import vmwareLogo from './vmware/logo.svg'
import vsphereLogo from './vsphere/logo.svg'
import scalewayLogo from './scaleway/logo.svg'
import scalewayLogoWithText from './scaleway/logoWithText.svg'
import ovhLogo from './ovh/logo.svg'
import alibabaLogo from './alibaba/logo.svg'
import oracleLogo from './oracle/logo.svg'

import flexibleEngineLogo from './flexeng/logo.png'

import azurermLogo from './azurerm/logo.svg'

import elasticSearchLogo from './elasticsearch/logo.svg'

import swiftLogo from './swift/logo.svg'

import gitlabLogo from './gitlab/logo.svg'

import openstackLogo from './openstack/logo.png'

import cloudsBanner from './backgrounds/clouds-banner.png'
import spaceOwl from './backgrounds/spaceowl.svg'

import ccmIllustration from './cloud-cost-management/ccm_v1.png'

import settingsIcon from './cg-icons/baseline-settings.png'
import noLink from './cg-icons/nolink.png'
import layout from './cg-icons/sitemap-solid.png'
import zoomToFit from './cg-icons/arrows-alt-h-solid.png'

import davidbowlie from './owls/davidbowlie.svg'
import deadpowl from './owls/deadpowl.svg'
import dowlek from './owls/dowlek.svg'
import emmetbrowl from './owls/emmetbrowl.svg'
import gandowlf from './owls/gandowlf.svg'
import hypnoowl from './owls/hypnoowl.svg'
import jacksparrowl from './owls/jacksparrowl.svg'

import organizations from './presentation/organizations.svg'
import more from './presentation/more.svg'
import catalogRepositories from './presentation/catalog-repositories.svg'
import configRepository from './presentation/config-repository.gif'
import pipelines from './presentation/pipelines.gif'
import privateCatalogs from './presentation/private-catalogs.gif'
import projects from './presentation/projects.gif'
import stacks from './presentation/stacks.png'
import monitoring from './presentation/monitoring.png'
import environmentsEmpty from './environments/empty.png'

import error500 from './errors/500_small.png'
import other from './errors/other_small.png'

import zeroState from './infra-import/zero-state.png'

export const owls = {
  davidbowlie,
  deadpowl,
  dowlek,
  emmetbrowl,
  gandowlf,
  hypnoowl,
  jacksparrowl,
}

export const presentation = {
  organizations,
  more,
  catalogRepositories,
  configRepository,
  privateCatalogs,
  pipelines,
  projects,
  stacks,
  monitoring,
}

export const aws = {
  logo: awsLogo,
  apiGateway: awsApiGateway,
  cloudfront: awsCloudFront,
  dynamodb: awsDynamodb,
  ec2: awsEc2,
  lambda: awsLambda,
  s3: awsS3,
}
export const vault = {
  logo: vaultLogo,
  logoColor: vaultLogoColor,
  logoWithText: vaultLogoWithText,
}
export const google = {
  logo: googleLogo,
}

export const kubernetes = {
  logo: kubernetesLogo,
}

export const vmware = {
  logo: vmwareLogo,
}
export const vsphere = {
  logo: vsphereLogo,
}
export const scaleway = {
  logo: scalewayLogo,
  logoWithText: scalewayLogoWithText,
}
export const ovh = {
  logo: ovhLogo,
}
export const alibaba = {
  logo: alibabaLogo,
}
export const oracle = {
  logo: oracleLogo,
}

export const flexibleengine = {
  logo: flexibleEngineLogo,
}

export const azurerm = {
  logo: azurermLogo,
}

export const gitlab = {
  logo: gitlabLogo,
}

export const elasticsearch = {
  logo: elasticSearchLogo,
}

export const swift = {
  logo: swiftLogo,
}

export const openstack = {
  logo: openstackLogo,
}

export const ansible = {
  logo: ansibleLogo,
}

export const terraform = {
  logo: terraformLogo,
}

export const infraImport = {
  zeroState,
}

export const ccm = {
  illustration: ccmIllustration,
}

export const environments = {
  empty: environmentsEmpty,
}

export const cgIcons = {
  settingsIcon,
  noLink,
  layout,
  zoomToFit,
}

export const backgrounds = {
  cloudsBanner,
  spaceOwl,
}

export const errorsIllu = {
  error500,
  other,
}

export default {
  alibaba,
  ansible,
  aws,
  azurerm,
  backgrounds,
  ccm,
  cgIcons,
  elasticsearch,
  errorsIllu,
  flexibleengine,
  gitlab,
  google,
  infraImport,
  openstack,
  ovh,
  owls,
  presentation,
  scaleway,
  swift,
  terraform,
  vault,
  kubernetes,
  vmware,
  vsphere,
}
